import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class _DatePicker extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            date: props.setup ? (props.setup.selected ? props.setup.selected.format(props.setup.dateFormat) : '') : '',
            ref: 'dpDate',
            disabled: true
        }
    }

    componentDidMount = () => {
        // Initialize something when the component is mounted
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    onChange = (date) => {
        if (this.props.onChange) {
            this.props.onChange(date)

            this.setState({
                date: date.format(this.props.setup.dateFormat)
            })
        }

        this.setState({
            isOpen: false
        })

    }

    handleClick = () => {
        this.toggle()
    }

    /**
     * 
     * Hide the Calendar when you click away from the calendar we hide
     */
    handleClickOutside = (e) => {

        // If the Click outside is just the date picker holder we should try to close the picker
        if (e && (e.target.id === 'dp-holder')) return;

        this.setState({
            isOpen: false
        })
    }

    render() {
        return (
            <div className="date-picker-holder">
                <div id="dp-holder" className="ui right corner labeled input dp-no-select" 
                    onClick={ this.handleClick.bind(this) }>
                    
                    <input ref={ this.state.ref } type="text" value={ this.state.date } readOnly/>
                </div>
                <div className="picker-container">
                    { this.state.isOpen ? (
                        <DatePicker
                            inline 
                            ref='picker'
                            { ...this.props.setup }
                            onChange={ this.onChange.bind(this) } 
                            onClickOutside={ this.handleClickOutside.bind(this) } />
                    ) : null }
                </div>
            </div>
        )
    }
}

_DatePicker.propTypes = {
    
}

export default _DatePicker