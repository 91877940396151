import React, { Component } from 'react'
import '.././styles/custom.css'
import { Message } from 'semantic-ui-react'

const CampaignExpiredMessage = (props) => (
    <div className='campaign-expired-message'>
        <Message negative onDismiss={props.dismissCampaignExpiredMsg.bind(this)}>
            <Message.Header>Session Expired</Message.Header>
            <p>Unfortunately your campaign setup has expired. Please create a new one.</p>
            <div className='center'>
                <a onClick={props.dismissCampaignExpiredMsg.bind(this)}>Dismiss</a>
            </div>
        </Message>
    </div>
)

export default CampaignExpiredMessage
