import React, { Component } from 'react'
import '.././styles/custom.css'
import { List, Icon } from 'semantic-ui-react'

class WhatWhereCol extends Component {

    constructor(props) {
        super()
    }

    render() {

        const { category, subcategory, amount, userCount, numSchools } = this.props

        return (
            <div className='when-where'>
                <div className='collapsed'>
                    <List>
                        {
                            category && subcategory
                            ?<List.Item as='a' disabled>
                                <Icon name='circle' size='small' />
                                <List.Content>
                                    <List.Header>Classification: <strong>{ category.name } - {subcategory.name}</strong></List.Header>
                                </List.Content>
                            </List.Item>
                            : null
                        }
                        <List.Item as='a' disabled>
                            <Icon name='circle' size='small' />
                            <List.Content>
                                <List.Header>Users: <strong>Up to {userCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} </strong> </List.Header>
                            </List.Content>
                        </List.Item>
                        <List.Item as='a' disabled>
                            <Icon name='circle' size='small' />
                            <List.Content>
                                <List.Header>Number of Schools: <strong>{ numSchools }</strong> </List.Header>
                            </List.Content>
                        </List.Item>
                        <List.Item as='a' disabled>
                            <Icon name='circle' size='small' />
                            <List.Content>
                                <List.Header>Total Cost: <strong>{ amount } (VAT incl.)</strong></List.Header>
                            </List.Content>
                        </List.Item>
                    </List>
                </div>
            </div>
        )
    }
}

export default WhatWhereCol

