import axios from 'axios'
import { keys, values, map, isArray, isEmpty } from 'lodash'
import Uri from './endpoints'

const baseURL = process.env.REACT_APP_API_BASE_URL

let Config = {
	apiUrl: baseURL
}


/**
 * Get peach payment status
 * @param urlParams
 * @return Promise
 */
export function getPeachPaymentsStatus (uri, urlParams=null) {
	
	return axios.get(getPeachEndpoint(uri, null, urlParams))
}


/**
 * Get categories
 * @return Promise
 */
export function getCategories () {
	
	let { GET_CATEGORIES } = Uri
	return axios.get(getEndpoint(GET_CATEGORIES, null))
}

/**
 * Get schools
 * @param urlParams
 * @return Promise
 */
export function getSchools (urlParams=null) {
	
	let { GET_SCHOOLS } = Uri
	return axios.get(getEndpoint(GET_SCHOOLS, null, urlParams))
}

/**
 * Get provinces filter
 * @return Promise
 */
export function getCountriesFilter (urlParams) {

	let { GET_COUNTRIES_FILTER } = Uri
	return axios.get(getEndpoint(GET_COUNTRIES_FILTER, null, urlParams))
}

/**
 * Get provinces filter
 * @return Promise
 */
export function getProvincesFilter (id, urlParams) {

	let { GET_PROVINCES_FILTER } = Uri
	return axios.get(getEndpoint(GET_PROVINCES_FILTER, id, urlParams))
}

/**
 * Get towns filter
 * @return Promise
 */
export function getTownsFilter (id, urlParams) {

	let { GET_TOWNS_FILTER } = Uri
	return axios.get(getEndpoint(GET_TOWNS_FILTER, id, urlParams))
}

/**
 * Get suburbs filter
 * @return Promise
 */
export function getSuburbsFilter (id, urlParams) {

	let { GET_SUBURBS_FILTER } = Uri
	return axios.get(getEndpoint(GET_SUBURBS_FILTER, id, urlParams))
}

/**
 * Get selected school by keyword
 * @return Promise
 */
export function getSelectedSchool (keywords) {
	
	let { GET_SELECTED_SCHOOL } = Uri
	return axios.get(getEndpoint(GET_SELECTED_SCHOOL, keywords), null)
}

/**
* Calculate cost
* @param payload 
* @return { AxiosPromise }
*/
export function calculateAmount (payload) {
	
	let { CALCULATE_AMOUNT } = Uri

	return axios.post(getEndpoint(CALCULATE_AMOUNT), payload)
}

/**
* Get payment plans
* @param payload 
* @return { AxiosPromise }
*/
export function getPlans (payload) {
	
	let { GET_PLANS } = Uri

	return axios.post(getEndpoint(GET_PLANS), payload)
}


/**
* Create submission
* @param payload 
* @return { AxiosPromise }
*/
export function submitListing (formData) {


	let { CREATE_SUBMISSION } = Uri

	return axios.post(Config.apiUrl + CREATE_SUBMISSION, formData, {
		headers: {
		  'Content-Type': 'multipart/form-data'
		}
	  })
}

/**
* Get subscriptions
* @param payload 
* @return { AxiosPromise }
*/
export function getSubscriptions (slug) {
	
	let { GET_SUBSCRIPTIONS } = Uri

	return axios.get(getEndpoint(GET_SUBSCRIPTIONS, slug), null)
}

/**
* Get checkout Id to update payment
* @param payload 
* @return { AxiosPromise }
*/
export function getCheckoutIDUpdatePayment (slug, id) {
	
	let { UPDATE_PAYMENT } = Uri

	var url =  Config.apiUrl + UPDATE_PAYMENT
	url =  url.replace('%s', slug)
	url =  url.replace('%s', id)

	return axios.get(url, null)
}

/**
* Update payment
* @param payload 
* @return { AxiosPromise }
*/
export function updatePayment (slug, id, payload) {
	
	let { UPDATE_PAYMENT } = Uri

	var url =  Config.apiUrl + UPDATE_PAYMENT
	url =  url.replace('%s', slug)
	url =  url.replace('%s', id)

	return axios.post(url, payload)
}

/**
* Get checkout Id to update payment
* @param payload 
* @return { AxiosPromise }
*/
export function cancelSubscription (slug, id) {
	
	let { CANCEL_SUBSCRIPTION } = Uri

	var url =  Config.apiUrl + CANCEL_SUBSCRIPTION
	url =  url.replace('%s', slug)
	url =  url.replace('%s', id)

	return axios.post(url)
}

/**
* Post send email
* @param payload 
* @return { AxiosPromise }
*/
export function postSendEmail (payload) {
	
	let { POST_SEND_EMAIL } = Uri

	return axios.post(getEndpoint(POST_SEND_EMAIL), payload)
}


/**
 * Return API endpoint with given path
 * @param path
 * @param id
 * @param urlParams
 * @returns {string}
 */
function getEndpoint ( path , id = null, urlParams = null ) {

	var url =  Config.apiUrl + path
	
	if (id) {
		url =  Config.apiUrl + path.replace("%s", id)
	}

	if(urlParams != null) {

		url += "?" 

		map(urlParams, (param, index) => {

			url += keys(param)[0] + "=" + values(param)[0]

			if(index != urlParams.length - 1)
			{
				url += "&"
			}

		})

	}
	
	return url
}

/**
 * Return API endpoint with given path
 * @param path
 * @param id
 * @param urlParams
 * @returns {string}
 */
function getPeachEndpoint ( path , id = null, urlParams = null ) {

	var url =  process.env.REACT_APP_PEACH_PAYMENTS + path
	
	if (id) {
		url =  process.env.REACT_APP_PEACH_PAYMENTS + path.replace("%s", id)
	}

	if(urlParams != null) {

		url += "?" 

		map(urlParams, (param, index) => {

			url += keys(param)[0] + "=" + values(param)[0]

			if(index != urlParams.length - 1)
			{
				url += "&"
			}

		})

	}
	
	return url
}

/**
 * Adds Url parameters to the parsed url
 * 
 * @param {String} url 
 * @param {String} params
 * @param {Integer} ids
 * @returns {String}
 */
function getEndpointWithParams (path, ids = null, params = null) {
	
	var url =  Config.apiUrl + path

	if (ids) {
		if (isArray(ids)) {
			url =  Config.apiUrl + path.replace("%s", ids[0]).replace("%ss", ids[1])
		} else {
			url =  Config.apiUrl + path.replace("%s", ids)
		}
	}

	if (!isEmpty(params)) {
		let _url = url.concat("?")
		let _params = ""
		
		map(params, (value, key) => _params = _params.concat(key + "=" + value).concat("&") )
		
		_params = _params.substring(0, _params.lastIndexOf("&"))

		return _url.concat(_params)
	}

	return url
}