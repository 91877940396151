const endpoints = {
    
    GET_CATEGORIES: "/v1/categories",
    GET_SCHOOLS: "/v1/schools",
    CALCULATE_AMOUNT: "/v1/price",
    GET_PLANS: "/v1/plans",
    CREATE_SUBMISSION: "/v1/submit",
    GET_COUNTRIES_FILTER: "/v1/schools/filter/countries",
    GET_PROVINCES_FILTER: "/v1/schools/filter/country/%s/provinces",
    GET_TOWNS_FILTER: "/v1/schools/filter/province/%s/towns",
    GET_SUBURBS_FILTER: "/v1/schools/filter/town/%s/suburbs",
    POST_SEND_EMAIL: "/v1/desktoplink",
    GET_SELECTED_SCHOOL: "/v1/schools/keywords?keywords=%s",
    GET_SUBSCRIPTIONS: "/v1/subscriptions/%s",
    UPDATE_PAYMENT: "/v1/subscriptions/%s/%s/update-payment",
    CANCEL_SUBSCRIPTION: "/v1/subscriptions/%s/%s/cancel",
}


export default endpoints;