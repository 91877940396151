import React, { Component } from 'react'
import '.././styles/custom.css'
import { Modal, Segment, Button, Form } from 'semantic-ui-react'


class PeachPaymentsModal extends Component {

    constructor(props) {
        super()
        this.mountPeachScript(props)
    }

    mountPeachScript = (props) => {
    
        if(document.getElementById("peach-payments-sdk")) {

            var elem = document.getElementById('peach-payments-sdk')
            elem.parentNode.removeChild(elem)

            elem = document.getElementById('peach-payments-sdk-settings')
            elem.parentNode.removeChild(elem)
        }

            var s = document.createElement("script") 
            s.type = "text/javascript"
            s.src = process.env.REACT_APP_PEACH_PAYMENTS + "/v1/paymentWidgets.js?checkoutId=" + props.checkoutId
            s.id  = "peach-payments-sdk"
            s.setAttribute("async", "")
            var n = document.getElementsByTagName("script")[0]
            n.parentNode.insertBefore(s, n)

            var s2 = document.createElement("script") 
            s2.id  = "peach-payments-sdk-settings"
            s2.innerHTML ='var wpwlOptions = {style:"plain"}'
            n = document.getElementsByTagName("script")[0]
            n.parentNode.insertBefore(s2, n)
    }


    render() {

        const{open, amount, onCancelClick} = this.props

        return (
            <Modal className="peach-payments-modal" open={ open }  size='fullscreen'>
                <Modal.Header>
                    Pay with Credit Card
                    <br/>
                    <p>Powered by Peach Payments</p><img className='peach-payments-logo' src="/images/peach_payments.svg" />                   
                </Modal.Header>
                <Modal.Content >
                    <Segment>
                        {
                            amount
                            ? <h3>Amount: <strong>{amount}</strong></h3> 
                            : null
                        }
                        <p>Please provide your payment details below:</p>
                        <br/>  
                        <Form loading action={process.env.REACT_APP_URL + this.props.resolveUrl} className="paymentWidgets" data-brands="VISA MASTER"></Form>
                    </Segment>
                </Modal.Content>
                    <Modal.Actions>
                    <Button color='red' onClick={onCancelClick.bind(this)}> Cancel
                    </Button>
                </Modal.Actions>
           </Modal>
        )
    }
}

export default PeachPaymentsModal
