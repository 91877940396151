import React, { Component } from 'react'
import { ChromePicker }     from 'react-color'
import Slider               from 'react-slick'
import { Label, Checkbox, Button, Segment, Loader, Icon,Rail, List } from 'semantic-ui-react'

import _ from 'lodash'


class PaymentOption extends Component {
  
    render() {

        var item = this.props.item
        return (
            <Segment className={'payment-option' + (this.props.selected ? ' selected' : '')}
            onClick={this.props.onClick.bind(this, item.id)}>
                
                <Checkbox checked={this.props.selected}/>
                <div style={{textAlign: 'center'}}>
                    <h4>{item.name}</h4>
                    {
                        item.discount_percentage
                        ? <Label color="teal">
                            { item.discount_percentage }% off
                        </Label>
                        : null
                    }   
                    <br />
                    <label>{ item.valid_until_formatted }</label>
                    <h3>{ 
                    item.price_formatted.indexOf('/') == -1 
                        ? item.price_formatted
                        : item.price_formatted.substr(0, item.price_formatted.indexOf('/')) }
                    {
                        item.price_formatted.indexOf('/') != -1 
                        ? <span className='per-month'>{ item.price_formatted.substr(item.price_formatted.indexOf('/')) }</span>
                        : null
                    }
                    </h3>
                    {
                        item.fallback_price_formatted
                        ? <label>{item.fallback_price_formatted} thereafter</label>
                        : null
                    }
                    <br/>
                    <br/>
                    <label>{item.description}</label>
                </div>
                    
            </Segment>
        )
    }
}


export default PaymentOption