import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import '.././styles/custom.css'
import { Segment, Button, Modal, Item, Message, Icon, Checkbox, Radio, Grid } from 'semantic-ui-react'

import ReactGA from 'react-ga'
import moment from 'moment-business-days'
import { map } from 'lodash'
import { constants } from '../constants'
import PaymentOption from './PaymentOption'
import PaymentOptionSelection from './PaymentOptionSelection'
import { getPlans } from '../api';

class PaymentExp extends Component {

    constructor(props) {
        super()

        this.state = {
            loading: false,
            paymentFailed: false,
            tcsAccepted : false,
            openPeachModal: false,
        }
    }



    onTCsAccepted  = ()=> {
    
        const checked = !this.refs.tcs.state.checked

        this.setState({

            tcsAccepted : checked
        })

        let button = document.getElementById("payment-button")

        
        if(checked){
            button.classList.remove('disabled')
        }
        else {
            button.classList.add('disabled')

        }
       
    }

    onOpenTCs = () => {
        this.setState({
            openTCs : true
        })
    }

    onCloseTCs = () => {
        this.setState({
            openTCs : false
        })
    }


    onClosed = () => {
        this.setState({
            loading: false
        })
    }


    scrollUp = () => {

        document.querySelector('#step-form').scrollIntoView({ 
            block: "start",
            behavior: 'smooth' 
          });
    }

    onTCsClicked = () => {
        
        return true
    }



    async getPlans() {

        const payload = {
            school_ids : this.props.schoolIds,
        }

        await getPlans(payload).then((response) => {

            this.setState({
                plans: response.data,
                planId: response.data.length > 0 ? response.data[0].id : -1
            }, ()=>{

                this.props.onPaymentOptionSelect(this.state.planId)
            })

        }).catch((error) => {
            this.setState({
                plansError: "Couldn't fetch payment plans. Please refresh or check internet connection."
            })
        })
    }

    componentDidMount() {

        if(this.props.schoolIds.length > 0 && !this.state.plans && !this.state.plansFetched) {
            this.getPlans()

            this.setState({
                plansFetched: true
            })
        }
       
    }

    render() {

        const { amount, error, hideSummary, calculating, loading } = this.props

        return (
            <div>
                <p>Please select your payment option below:</p>
                <br/>
                <br/>

                {
                    this.state.plansError
                    ? <Message className='red center'>
                        <p>
                            {this.state.plansError}
                        </p>
                    </Message>
                    : <PaymentOptionSelection
                        loading={loading}
                        paymentOptions={this.state.plans}
                        onPaymentOptionSelect={this.props.onPaymentOptionSelect.bind(this)}/>
                }
               
           
                <Segment basic textAlign='center'>

                    <label>Please note that you can cancel your subscription at any time.</label>

                    {
                        hideSummary 
                        ?  <p className='summary-button' onClick={ this.scrollUp.bind(this) }>
                                <Icon name='arrow up'/> View Summary
                            </p>
                        : null
                    }
            

                    <Segment className='price' textAlign='center' loading={calculating} >
                        <h2>{ 
                                amount.indexOf('/') == -1 
                                ? amount
                                : amount.substr(0, amount.indexOf('/')) 
                            }
                            {
                                amount.indexOf('/') != -1 
                                ? <span className='per-month'>{ amount.substr(amount.indexOf('/')) }</span>
                                : null
                            }
                        </h2>
                        <label className='primary'>VAT incl.</label>
                    </Segment>

                    <label className='primary' style={{fontSize:'12px'}}>50% of the above amount will be rewarded to your selected schools.</label>
                   
                    <br/>
                    <br/>

                    <Checkbox ref='tcs' checked={this.state.tcsAccepted} label='I accept the ' onChange={this.onTCsAccepted.bind(this)}/><a onClick={this.onTCsClicked.bind(this)} target='blank' href='https://bit.ly/d6LocalListingsAgreement'> Terms &amp; Conditions</a>
                    <br/>
                    <br/>
                    <Message info className='primary olive'>
                        <p style={{textAlign: 'left'}}>Please note:</p>
                        <Message.List>
                            <Message.Item>A max of 2 business days are needed to approve your listing.</Message.Item>
                            <Message.Item>You can cancel your subscription at any time.</Message.Item>
                            <Message.Item>If you select a once-off payment, it will switch to a month-to-month subscription once ended.</Message.Item>
                        </Message.List>
                    </Message>
                    <br/>

                    {
                    error
                    ? <Message className='red center'>
                        <p>
                            <strong>{error}</strong>
                            <br/>
                            <br/>
                            Payment Failed. Please try again or <a target="_blank" href='https://d6.co.za/media/local-listings/'><strong>Contact Us</strong></a> to assist.
                        </p>
                    </Message>
                    : null
                }

                    <Segment basic>

                        <button 
                                id="payment-button" 
                                className={'ui button d6-media-green'  + ((this.state.loading && !error) || !this.state.tcsAccepted ? ' disabled' : '')}
                                onClick={this.props.showUserDetails.bind(this)}
                                >
                                    Pay
                        </button>
                        <br />
                        <br />
                    
                    </Segment>

                    <Modal open={ this.state.openTCs } size='tiny'>
                        <Modal.Content >

                            <p>Terms &amp; Conditions Here</p>

                        </Modal.Content>
                        <Modal.Actions>
                        <Button
                            className='d6-media-green'
                            content="Close"
                            onClick={this.onCloseTCs.bind(this)}
                        />
                            

                        </Modal.Actions>
                    </Modal>

                   
                    
                    
                </Segment>
            </div>
        )
    }
}

export default PaymentExp

