import React, { Component } from 'react'
import '.././styles/custom.css'
import { Message, Label, Icon } from 'semantic-ui-react'
import { map } from 'lodash'


class SelectedBox extends Component {


    constructor(props) {
        super()
    }

    render() {

        const { selection, onRemoveItem, onClick } = this.props

        return (

            <div>

                <Message className='selected-box'>
                
                    {
                        selection && selection.length > 0
                            ?
                            map(selection, (item, key) => {

                                return (
                                    <Label key={key}>
                                        {item.text} <Icon name='delete' onClick={onRemoveItem.bind(this, item)} />
                                    </Label>
                                )

                            })
                            : <label className='red'>
                                Please select at least one. 
                             </label>
                            
                    }

                     {
                        onClick
                        ? <Label onClick={onClick ? onClick.bind(this) : ()=>{}} className='add-school-label'><Icon name='plus'/> Add</Label>
                        : null
                    }
                   

                </Message>

            </div>
        )
    }
}

export default SelectedBox