import React, { Component } from 'react'
import '.././styles/custom.css'
import { Segment, Modal, Input, Button, Message } from 'semantic-ui-react'

class EmailModal extends Component {

    constructor(props) {
        super()

        this.state = {
            email: '',
            emailError: true,
            loading: false
        }
    }


    onEmailChange = (e) => {

        this.setState({
            email: e.target.value,
            emailError: !this.validateEmail(e.target.value),
        })

    }

    onError = () => {

        this.setState({

            loading: false
        })
    }


    onSendClick = () => {

        this.setState({

            loading: true
        })
        this.props.onSendClick(this.state.email, window.location.href, this.onError.bind(this))

    }

    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    componentDidMount = () => {
       
        try {
            const b = new URLSearchParams(window.location.search)
            if(b)
            {
                const paramsString = atob(b.get('d'))
            
                    const params = JSON.parse(paramsString)
                    this.setState({
                        email: params.email,
                        emailError: !this.validateEmail(params.email),
                    })

            }
        }
        catch(e){}
        

        this.setState({
            loading: false
        })
    }

    onOpen = () => {
        this.setState({
            loading: false
        })

    }


    render() {

        const{ 
            message, 
            cancelButtonCaption, 
            sendButtonCaption, 
            onCancelClick,
            open,
            error} = this.props

        return (
            <Modal open={ open } onOpen={ this.onOpen.bind(this) }  size='tiny'>
                <Modal.Content >

                    { error === true
                        ? <Message className='red center'>
                            <p>
                                There was a problem sending the email. Please check your email and try again. 
                            </p>
                        </Message>
                        : null
                    }
            
                    { message }
            
                    <Segment className='center'>
                        <Input 
                            disabled={ this.state.loading }   
                            placeholder='Email' 
                            value={ this.state.email }
                            onChange={this.onEmailChange.bind(this)} 
                            className={this.state.emailError ? 'error' : ''} 
                            ref='email'/>
                        <br />
                        <br />
                        <Button loading={ this.state.loading && !error } className={'d6-media-green' + (this.state.emailError || this.state.loading ? ' disabled' : '')} onClick={this.onSendClick.bind(this)}>{ sendButtonCaption }</Button>
                        <br />
                        <br />
                        <a className={(this.state.loading ? ' disabled' : '')} onClick={ onCancelClick.bind(this, '') }>{ cancelButtonCaption }</a>
                    </Segment>
                </Modal.Content>
           </Modal>
        )
    }
}

export default EmailModal
