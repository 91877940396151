const consts = {

    /** STEP STATES */
    STATE_ACTIVE: "active",
    STATE_INACTIVE: "inactive",
    STATE_COMPLETE: "complete",

    PER_PAGE_OPTIONS: [

        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: "25", value: 25 }
    ],

    /** SUBSCRIPTION STATUSES */
    STATUS_PENDING_SETUP: "pending_setup",
    STATUS_ACTIVE: "inactive",
    STATUS_CANCELLED: "cancelled",

}

export function constants() {
    return consts
}