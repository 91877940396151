import React, { Component } from 'react'
import '.././styles/custom.css'
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';

const Handle = Slider.Handle;

const handle = (props) => {

    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="bottom"
            key={index}>
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};

const wrapperStyle = { width: '100%', marginTop: 30 };

class InputSlider extends Component {

    constructor(props) {
        super()
    }

    render() {

        const { min, max, defaultValue, onAfterChange, onBeforeChange } = this.props

        return (
            <div className='slider'>
                <div style={wrapperStyle}>
                    <Slider 
                        min={min} 
                        max={max} 
                        defaultValue={defaultValue} 
                        handle={handle} 
                        onBeforeChange={onBeforeChange.bind(this)}
                        onAfterChange={onAfterChange.bind(this)} />
                </div>
            </div>

        )
    }
}

export default InputSlider
