import React, { Component } from 'react'
import { assign, concat, find, countBy } from 'lodash'
import '.././styles/custom.css'
import { constants } from '../constants'
import CollapsableStepContainer from '../components/CollapsableStepContainer'
import WhatWhereCol from '../views/WhatWhereCol'
import WhatWhereExp from '../views/WhatWhereExp'
import PaymentExp from '../views/PaymentExp'
import ListingExp from './ListingExp'
import ListingCol from './ListingCol'
import UserInfoModal from './UserInfoModal'
import PeachPaymentModal from './PeachPaymentsModal'
import moment from 'moment-business-days'
import ReactGA from 'react-ga'

import { calculateAmount, submitListing } from '../api'
import { Segment } from 'semantic-ui-react';

class StepForm extends Component {

    constructor(props) {
        super()

        this.initLocalStorage(props)
    
        var alreadyCompleted = localStorage.getItem("alreadyCompleted")
        this.state = {

            collapsed: props.collapsed,
            states: JSON.parse(localStorage.getItem("states")),
            currentStep: parseInt(localStorage.getItem("currentStep")),
            alreadyCompleted: JSON.parse(alreadyCompleted),
            whatWhere: JSON.parse(localStorage.getItem("whatWhereStep")),
            listing: JSON.parse(localStorage.getItem("listingStep")),
            error: null,
            planId: null,
            fetchingCost: false
        }

    }

    initLocalStorage = (props) => {

        var startDate = moment().businessAdd(3).format('YYYY-MM-DD')
        var endDate = moment().businessAdd(3).add(10, 'days').format('YYYY-MM-DD')


        if(localStorage.getItem("stepForm") == null)
        {
            //set init local storage
            localStorage.setItem("stepForm", true)
            localStorage.setItem("currentStep", 1)
            localStorage.setItem("alreadyCompleted", JSON.stringify([]))
            localStorage.setItem("whatWhereStep", JSON.stringify( {
                startDate,
                endDate,
                amount: 'R 0.00',
                userCount: 0,
                schoolIds: []
            }))
            localStorage.setItem("listingStep", JSON.stringify( {
                email: '',
                website: '',
                logo: '',
                address: '',
                businessName: '',
                contactNumber: '',
                description: '',
            }))
            localStorage.setItem("states", JSON.stringify(  {
                1: constants().STATE_ACTIVE,
                2: constants().STATE_INACTIVE,
                3: constants().STATE_INACTIVE
            }))
        
        }
    }


    nextStep = (step, id) => {


        switch (step) {
            case 1:
                window.gtag('event', 'what_where_next_click', {
                    'event_category': 'What & Where',
                    'event_label': 'On Continue Button Clicked',
                  })

                break;
            case 2:
                window.gtag('event', 'listing_next_click', {
                    'event_category': 'Listings',
                    'event_label': 'On Continue Button Clicked'
                  })
                break;
            case 3:
                window.gtag('event', 'payment_pay_click', {
                    'event_category': 'Payment',
                    'event_label': 'On Pay Button Clicked'
                  })
                break;
        
            default:
                break;
        }
        

        var nextId = step + 1

        setTimeout(function() {
            document.querySelector('#step-' + nextId).scrollIntoView({ 
                block: "start",
                behavior: 'smooth' 
            });
          }, 450);


        this.setState({

            alreadyCompleted:
                this.state.alreadyCompleted.includes(step)
                    ? this.state.alreadyCompleted
                    : concat(this.state.alreadyCompleted, step),

            currentStep: step + 1,
            states: assign(this.state.states, {
                [step + 1]: constants().STATE_ACTIVE,
                [step]: constants().STATE_COMPLETE
            }),

        }, ()=> {

            localStorage.setItem("alreadyCompleted", JSON.stringify(this.state.alreadyCompleted))
            localStorage.setItem("currentStep", this.state.currentStep)
            localStorage.setItem("states", JSON.stringify(this.state.states))
        })

        

        

    }

    changeCurrentStep = (step, id) => {

        if (step !== this.state.currentStep) {

            setTimeout(function() {
                document.querySelector('#' + id).scrollIntoView({ 
                    block: "start",
                    behavior: 'smooth' 
                });
              }, 450);

            this.setState({
                currentStep: step,
                states: assign(this.state.states, {
                    [step]: constants().STATE_ACTIVE,
                    [this.state.currentStep]:
                        this.state.alreadyCompleted.includes(this.state.currentStep)
                            ? constants().STATE_COMPLETE
                            : constants().STATE_INACTIVE,
                }),

            }, ()=> {

                localStorage.setItem("alreadyCompleted", JSON.stringify(this.state.alreadyCompleted))
                localStorage.setItem("currentStep", this.state.currentStep)
                localStorage.setItem("states", JSON.stringify(this.state.states))

            })
        }

    }

    /* WHAT WHERE */

    onCategorySeleted = (category) => {
        this.setState({
            whatWhere: assign(this.state.whatWhere, {"category": category}),
        }, ()=> {

            localStorage.setItem("whatWhereStep", JSON.stringify(this.state.whatWhere))
        })
    }

    onSubcategorySeleted = (subcategory) => {
        this.setState({
            whatWhere: assign(this.state.whatWhere,  {"subcategory": subcategory}),
        }, ()=> {

            localStorage.setItem("whatWhereStep", JSON.stringify(this.state.whatWhere))
        })
    }

    onUserCountChange = (userCount, schoolIds, callback) => {

        this.setState({
            whatWhere: assign(this.state.whatWhere, { userCount, schoolIds }),
        }, 
        ()=> {

            localStorage.setItem("whatWhereStep", JSON.stringify(this.state.whatWhere))
        })

        this.calAmount(schoolIds, callback)
    }

    onPaymentOptionSelect = (planId) => {

        this.setState({
            planId,
            fetchingCost: true
        }, ()=> {
            this.calAmount(this.state.whatWhere.schoolIds)
        })

       
    }
    
    async calAmount(schoolIds, callback) {

        var payload = {
            school_ids : schoolIds,
        }
        
        if(this.state.planId) {
            payload = assign(payload, {
                plan_id: this.state.planId,
            })
        }

        await calculateAmount(payload).then((response) => {

            const data = response.data

            this.setState({

                whatWhere: assign(this.state.whatWhere, { amount: data.price_formatted }),
                fetchingCost: false,
                plan_id: data.plan_id
            }, 
            ()=> {

                localStorage.setItem("whatWhereStep", JSON.stringify(this.state.whatWhere))
            })

            if(callback)
                callback()
           

        }).catch((error) => {

            this.setState({

                whatWhere: assign(this.state.whatWhere, { amount: "Error while Calculating Cost" }),
            },
            ()=> {

                localStorage.setItem("whatWhereStep", JSON.stringify(this.state.whatWhere))
            })

            callback()

        })
    }


    /* Listing */
    updateListing = (listingName, listingData) => {
        this.setState({
            listing: assign(this.state.listing, {[listingName]: listingData})
        }, 
        ()=> {

            localStorage.setItem("listingStep", JSON.stringify(this.state.listing))
        })
    }

    checkListingCompletion = (businessName, contactNumber, error) => {
       this.setState( {
        listing: assign(this.state.listing,{
                businessName,
                contactNumber,
                error
            })
        }, 
        ()=> {

            localStorage.setItem("listingStep", JSON.stringify(this.state.listing))
        })
    }

    onURLValidated = (website, error) => {
        this.setState( {
            listing: assign(this.state.listing,{
                 error,
                 website
             })
         }, 
         ()=> {
 
             localStorage.setItem("listingStep", JSON.stringify(this.state.listing))
         })

    }

    showUserDetails = () => {

        this.setState({
            
            openUserInfoModal: true,
        })
    }

    onSendSubmition = (user) => {

        this.setState({
            user
        }, ()=>{

            this.sendSubmission()
        })

    }

    onOpenPeachModal = (checkoutId) => {

        this.setState({
            openUserInfoModal: false,
            openPeachModal: true,
            checkoutId,
        })

    }

    onPeachModalCancel = () => {
        this.setState({
            openPeachModal: false,
            loading: false,
        })
    }

     /* FINAL SUBMISSION */
     async sendSubmission() {

            this.setState({
                error: null
            })


            const blob = await fetch(this.state.listing.logo).then(res => res.blob())

            const formData = new FormData();

             //Listing

             for (var i = 0; i < this.state.whatWhere.schoolIds.length; i++) {
                formData.append('school_ids[]', this.state.whatWhere.schoolIds[i]);
            }

            formData.append('name', this.state.listing.businessName)
            formData.append('phone_number', this.state.listing.contactNumber)
            formData.append('logo', blob)

             // Classify
            formData.append('sub_category_id', this.state.whatWhere.subcategory.value)

            //Payment
            formData.append('plan_id', this.state.planId)

             //Personal Details
            formData.append('contact_first_name', this.state.user.firstName)
            formData.append('contact_last_name', this.state.user.lastName)
            formData.append('contact_email', this.state.user.email)
            formData.append('contact_phone_number', this.state.user.cellphone)

        

            if(this.state.listing.address){
                formData.append('address', this.state.listing.address)
            }

            if(this.state.listing.description){
                formData.append('short_description', this.state.listing.description)
            }

            if(this.state.listing.website){
                formData.append('website', this.state.listing.website)
            }

            if(this.state.listing.email){
                formData.append('email', this.state.listing.email)

            }

            if(this.state.user.businessName){
                formData.append('billing_trade_name', this.state.user.businessName)
            }

            if(this.state.user.vatNumber){
                formData.append('billing_vat_number', this.state.user.vatNumber)
            }

            if(this.state.user.businessAddress){
                formData.append('billing_business_address', this.state.user.businessAddress)
            }
            if(this.state.user.salesExecutiveCode){
                formData.append('sales_code', this.state.user.salesExecutiveCode)
            }

            await submitListing(formData).then((response) => {
            
                localStorage.setItem("checkoutId", response.data.peach_checkout_id)
                localStorage.setItem("selectedSubscriptionId", response.data.subscription_id)
                localStorage.setItem("slug", response.data.slug)

                this.setState({
                    checkoutId: response.data.peach_checkout_id,
                    selectedSubscriptionId: response.data.subscription_id,
                    slug: response.data.slug,
                })

                this.onOpenPeachModal(response.data.peach_checkout_id)


            }).catch((error) => {

                this.setState({
                    error: error.response.data.error,
                    openUserInfoModal: false
                })
            })
        
     }


    render() {

        return (
            <div id='step-form'>

                {/* Step 1 : When & Where */}
                <div id='step-1'></div>
                <CollapsableStepContainer
                    expanded={this.state.currentStep === 1}
                    step={1}
                    id='step-1'
                    continueEnabled={this.state.whatWhere.userCount > 0}
                    border={true}
                    onNextClick={this.nextStep.bind(this)}
                    onToggleExpanded={this.changeCurrentStep.bind(this)}
                    title='What &amp; Where'
                    state={this.state.states[1]}
                    expandedView={

                        <WhatWhereExp
                            onCategorySeleted={this.onCategorySeleted.bind(this)}
                            onSubcategorySeleted={this.onSubcategorySeleted.bind(this)}
                            amount={this.state.whatWhere.amount}
                            userCount={this.state.whatWhere.userCount}
                            onUserCountChange={this.onUserCountChange.bind(this)}
                            planId={this.state.planId} />

                    }
                    collapsedView={

                        <WhatWhereCol
                            category={this.state.whatWhere.category}
                            subcategory={this.state.whatWhere.subcategory}
                            amount={this.state.whatWhere.amount}
                            userCount={this.state.whatWhere.userCount}
                            numSchools={this.state.whatWhere.schoolIds.length}  />

                    } />

                {/* Step 2 : Listing */}
                <div id='step-2'></div>
                <CollapsableStepContainer
                    expanded={this.state.currentStep === 2}
                    step={2}
                    id='step-2'
                    continueEnabled={this.state.listing.businessName.length > 0 && 
                        this.state.listing.contactNumber.length  > 0 && 
                        !this.state.listing.error
                    }
                    border={true}
                    onNextClick={this.nextStep.bind(this)}
                    onToggleExpanded={this.changeCurrentStep.bind(this)}
                    state={this.state.states[2]}
                    title='Listing'
                    expandedView={

                        <ListingExp 
                            onURLValidated={ this.onURLValidated.bind(this) }
                            onUpdate={ this.updateListing.bind(this) }
                            checkListingCompletion={ this.checkListingCompletion.bind(this) }/>

                    }
                    collapsedView={

                        <ListingCol
                            listing={ this.state.listing } 
                            />

                    } />

                {/* Step 3 : Payment */}
                <div id='step-3'></div>
                <CollapsableStepContainer
                    expanded={this.state.currentStep === 3}
                    step={3}
                    id='step-3'
                    onToggleExpanded={this.changeCurrentStep.bind(this)}
                    state={this.state.states[3]}
                    title='Payment'
                    expandedView={

                        <PaymentExp
                            loading={this.state.fetchingCost}
                            amount={this.state.whatWhere.amount} 
                            onProcessCompleted={ this.props.onProcessCompleted.bind(this) }
                            error={ this.state.error }
                            showUserDetails={this.showUserDetails.bind(this)}
                            schoolIds={this.state.whatWhere.schoolIds}
                            onPaymentOptionSelect={this.onPaymentOptionSelect.bind(this)}/>
                    }
                    collapsedView={

                        <div></div>

                    } />

                    {
                        this.state.openUserInfoModal
                        ? <UserInfoModal 
                            open={ this.state.openUserInfoModal }
                            message={
                                <div>
                                    <h2>Contact & Billing</h2>

                                    <p>We would love to keep in contact with you. You will receive the following email notifications:</p>
                                    <ul>
                                        <li>When you submit your listing</li>
                                        <li>When your listing has been approved</li>
                                        <li>When your card is charged every month</li>
                                        <li>Any other important periodic notices</li>
                                    </ul>
                                </div>
                            } 
                            onSendClick={ this.onSendSubmition.bind(this) }
                            sendButtonCaption='Continue'
                            businessName={this.state.listing.businessName}
                            businessAddress={this.state.listing.address} />
                            : null
                    }

                    {
                        this.state.openPeachModal
                        ? <PeachPaymentModal 
                            open={this.state.openPeachModal} 
                            checkoutId={this.state.checkoutId}
                            amount={this.state.whatWhere.amount}
                            onCancelClick={this.onPeachModalCancel.bind(this)}
                            resolveUrl='/peach-payments/payed'/>
                        : null

                    }

            </div>
        )
    }
}

export default StepForm
