import React, { Component } from 'react'
import { Card, Label, Button, Segment, Loader, Icon,Rail, List, Message, Modal } from 'semantic-ui-react'
import { constants } from '../constants'
import _, { iteratee } from 'lodash'



class Subscription extends Component {

    constructor() {

        super()

        this.state = {

            openCancelModal: false
        }

    }

    onCancelSubscription = () => {

        this.setState({

            openCancelModal: false,
            currentSubscription: null
        })

        this.props.onCancelSubscription(this.state.currentSubscription.id)
    }

    onOpenCancelModal = (currentSubscription) => {
        this.setState({

            openCancelModal: true,
            currentSubscription
        })
    }

    onCloseCancelModal = () => {
        this.setState({

            openCancelModal: false,
            currentSubscription: null
        })
    }

    onUpdatePayment = (id) => {

        localStorage.setItem('selectedSubscriptionId', id)
        this.props.onUpdatePayment(id)
    }
  
    render() {

        const { subscription } = this.props

        return (
            <div className={'subscription ' + subscription.status}>
                <Card>
                    <Card.Content>

                    {subscription.status == constants().STATUS_CANCELLED 
                            ? null 
                            : <a className='cancel-link' 
                                    style={{float:'right'}} 
                                    onClick={this.onOpenCancelModal.bind(this, subscription)}>
                                        Cancel Subscription
                                </a> 
                        }
        
                        {subscription.status == constants().STATUS_PENDING_SETUP 
                            ? <Label style={{float:'left'}} as='a' color='yellow' ribbon>
                                    Pending
                            </Label> 
                            : subscription.status == constants().STATE_ACTIVE
                                ? <Label style={{float:'left'}} as='a' color='olive' ribbon>
                                        Active
                                </Label>
                                : subscription.status == constants().STATUS_CANCELLED
                                    ? <Label style={{float:'left'}} as='a' color='grey' ribbon>
                                            Cancelled
                                    </Label>
                                    : <Label style={{float:'left'}} as='a' color='grey' ribbon>
                                            Unknown
                                    </Label>
                        }
                            
                        
                        <h4 className='business-name'>{subscription.name}</h4>
                        <br/>
                        <br/>
                        <Card.Description>
                            <p>Start Date: <strong>{subscription.start_date}</strong></p>
                            <p>Renewal date: <strong>{subscription.valid_until}</strong></p>
                            <p>Number of Schools: <strong>{subscription.num_schools}</strong></p>
                            <br/>

                            {
                                subscription.status == constants().STATE_ACTIVE
                                ? <a className='primary' 
                                    style={{float:'right'}} 
                                    onClick={this.onUpdatePayment.bind(this, subscription.id)}>
                                        Update Payment Details
                                </a> 
                                : null
                            }
                              
                            <Card.Meta><Icon 
                                    name='credit card outline'
                                    style={{marginRight:'10px', marginBottom:'10px'}}/>Payment
                            </Card.Meta>
                            

                            <p>Amount: <strong>{subscription.price_formatted}</strong></p>
                            <p>Plan: <strong>{subscription.plan_name}</strong></p>
                            {
                                subscription.has_payment_issue
                                ? <p>Card Details: <strong className='danger'>{subscription.card_details}{!subscription.has_payment_issue ? ' - Payment failed, please update payment details' : ''}</strong></p>
                                : <p>Card Details: <strong>{subscription.card_details}</strong></p>
                            }
                            
                        </Card.Description>
                    </Card.Content>
                </Card>

                {
                   this.state.openCancelModal
                   ? <Modal open={ this.state.openCancelModal } size='tiny'>
                        <Modal.Header>
                            Cancel Subscription
                        </Modal.Header>
                        <Modal.Content >
                            <p>Are you sure you would like to cancel your subscription? If yes, please note that <em>{this.state.currentSubscription.name}</em> will continue to run until <strong>{this.state.currentSubscription.valid_until}</strong> and will then not renew.</p>
                        </Modal.Content>
                        <Modal.Actions>
                        <Button
                            className='red'
                            content="No"
                            onClick={this.onCloseCancelModal.bind(this)}
                        />
                        <Button
                            className='d6-media-green'
                            content="Yes, Cancel Subscription"
                            onClick={this.onCancelSubscription.bind(this)}
                        />

                        </Modal.Actions>
                    </Modal>
                   : null 
                }
                
            </div>
        )
    }
}


export default Subscription