import React, { Component } from 'react'
import '.././styles/custom.css'
import Expand from 'react-expand-animated'
import { constants } from '../constants'
import { Grid, Label, Icon, Button } from 'semantic-ui-react'


class CollapsableStepContainer extends Component {

    constructor(props) {

        super()
    }

    render() {

        const { title, expanded, step, collapsedView, expandedView, state, border, onNextClick, onToggleExpanded, continueEnabled, id } = this.props

        return (
            <div className='step'>
                <Grid>
                    <Grid.Row onClick={state !== constants().STATE_INACTIVE ? onToggleExpanded.bind(this, step, id) : null} className='pointer'>

                        {/* Number Label */}
                        <Grid.Column mobile={3} tablet={2} computer={1}>
                            <Label circular size='large' className={'step-label ' + state}>
                                {state === constants().STATE_COMPLETE
                                    ? <Icon name='check' />
                                    : step
                                }
                            </Label>
                        </Grid.Column>

                        {/* Title */}
                        <Grid.Column mobile={10} tablet={12} computer={15}>
                            <h2 className={state + " title"}>{title}</h2>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row >

                        {/* Content */}
                        <Grid.Column width={16}>
                            <div className={(border ? 'border ' : '') + 'main-block'}>
                                <div className={'content-block ' + state} >

                                    {/* Expanded View */}
                                    
                                    <Expand open={expanded} duration={400}>
                                    
                                        { expanded ? expandedView  : null}

                                        {onNextClick
                                            ? <div className='continue-button-container'>
                                                 <Button className={(continueEnabled ? '' : 'disabled ') + 'd6-media-green'} onClick={continueEnabled ? onNextClick.bind(this, step, id) : () => { }}>
                                                    <Button.Content >Continue</Button.Content>
                                                </Button>
                                            </div>
                                            : null
                                        }
                                    </Expand>
                                

                                    {/* Collapsed View */}
                                    <Expand open={!expanded && state !== constants().STATE_INACTIVE} duration={400}>
                                        {collapsedView}
                                    </Expand>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default CollapsableStepContainer
