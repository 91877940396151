import React, { Component } from "react"
import { Dropdown } from 'semantic-ui-react'

class PaginationDropdown extends Component {

    onPaginationIntervalChange = (e, { value }) => {

        this.props.onPaginationIntervalChange(value)
    }

    render() {

        const { additionalClasses, perPageMenu, onPaginationIntervalChange, defaultValue } = this.props

        return (

            <div className={additionalClasses}>
                Per Page:  <Dropdown onChange={this.onPaginationIntervalChange.bind(this)} inline options={perPageMenu}
                    defaultValue={defaultValue ? defaultValue : perPageMenu[0].value} />
            </div>
        )
    }

}

export default PaginationDropdown