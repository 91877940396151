import React, { Component } from 'react'
import './styles/custom.css'
import Router from './layouts/Router'


class App extends Component {


    render() {
        return (
            <div className="App">
                <Router />
            </div>
        )
    }
}

export default App;
