import React, { Component } from "react"
import { Icon } from 'semantic-ui-react'

class PaginationNav extends Component {

    onIncrementPage() {

        this.props.onPageChange(this.props.currentPage + 1)

    }

    onDecrementPage() {

        this.props.onPageChange(this.props.currentPage - 1)

    }

    render() {

        const { totalPages, onPageChange, currentPage } = this.props

        return (
            <div>
                {currentPage > 1 ?
                    <Icon name='left chevron' style={{ float: 'left', cursor: "pointer" }} onClick={this.onDecrementPage.bind(this)} /> :
                    null
                }
                <p style={{ float: 'left' }}>{currentPage} of {totalPages > 0 ? totalPages : 1}</p>
                {currentPage < totalPages ?
                    <Icon style={{ cursor: "pointer" }} name='right chevron' onClick={this.onIncrementPage.bind(this)} /> :
                    null
                }
            </div>
        )
    }

}

export default PaginationNav