import React, { Component } from 'react'
import '.././styles/custom.css'
import {assign} from 'lodash'
import HelpTip from '../components/HelpTip'
import { Grid, Modal, Input, Button, Message, Segment,  } from 'semantic-ui-react'
import ReactGA from 'react-ga'


class UserInfoModal extends Component {

    constructor(props) {
        super()

        this.initLocalStorage(props)

        var user = JSON.parse(localStorage.getItem("user"))
        user = assign(user, {
            'businessName': props.businessName ? props.businessName : '',
            'businessAddress': props.businessAddress ? props.businessAddress : ''
        })

        this.state = {
            emailError: true,
            nameError: true,
            surnameError: true,
            cellphoneError: true,
            loading: false,
            user: user
        }
    }

    initLocalStorage = (props) => {


        if(localStorage.getItem("userInfo") == null)
        {
            //set init local storage
            localStorage.setItem("userInfo", true)
            localStorage.setItem("user", JSON.stringify({
                email: '',
                firstName: '',
                lastName: '',
                cellphone: '',
                salesExecutiveCode: '',
                businessName: props.businessName ? props.businessName : '',
                vatNumber: '',
                businessAddress: props.businessAddress ? props.businessAddress : ''
            }))
                            
        }
    }

    /*Contact Info */
    onEmailChange = (e) => {

        this.setState({
            user: assign(this.state.user, {email: e.target.value}),
            emailError: !this.validateEmail(e.target.value),
        })

    }

    onNameChange = (e) => {

        this.setState({
            user: assign(this.state.user, {firstName: e.target.value}),
            nameError: !this.validateEmptyField(e.target.value),
        })

    }

    onSurnameChange = (e) => {

        this.setState({
            user: assign(this.state.user, {lastName: e.target.value}),
            surnameError: !this.validateEmptyField(e.target.value),
        })

    }

    onCellphoneChange = (e) => {

        this.setState({
            user: assign(this.state.user, {cellphone: e.target.value}),
            cellphoneError: !this.validateEmptyField(e.target.value),
        })

    }

    /*Other*/
    onSalesCodeChange = (e) => {

        this.setState({
            user: assign(this.state.user, {salesExecutiveCode: e.target.value}),
        })

    }

    /*Business Info */
    onBusinessNameChange = (e) => {

        this.setState({
            user: assign(this.state.user, {businessName: e.target.value}),
        })
    }

    onVatNumberChange = (e) => {

        this.setState({
            user: assign(this.state.user, {vatNumber: e.target.value}),
        })
    }

    onAddressChange = (e) => {

        this.setState({
            user: assign(this.state.user, {businessAddress: e.target.value}),
        })
    }
    

   

    onError = () => {

        this.setState({

            loading: false
        })
    }


    onSendClick = () => {
        
        this.setState({

            loading: true
        })
        this.props.onSendClick(this.state.user, this.onError.bind(this))

    }

    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    validateEmptyField = (field) => {

        return field.length > 0
    }

    componentDidMount = () => {
       
        try {
            const b = new URLSearchParams(window.location.search)
       
            const email = b.has('em') && !b.get('em').includes("{{") ? b.get('em') : ''
            const firstName = b.has('fn') && !b.get('fn').includes("{{") ? b.get('fn') : ''
            const lastName = b.has('ln') && !b.get('ln').includes("{{") ? b.get('ln') : ''
            const cellphone = b.has('cl') && !b.get('cl').includes("{{") ? b.get('cl') : ''

            this.setState({
                user: assign(this.state.user, {
                    email,
                    firstName,
                    lastName,
                    cellphone

                }),
                emailError: !this.validateEmail(email),
                nameError: !this.validateEmptyField(firstName),
                surnameError: !this.validateEmptyField(lastName),
                cellphoneError: !this.validateEmptyField(cellphone),

            })
        }
        catch(e){}

        
        

        this.setState({
            loading: false
        })
    }



    render() {

        const{ 
            message, 
            sendButtonCaption, 
            open,
            error} = this.props

        return (
            <Modal open={ open }  size='small'>
                <Modal.Content >

                    { error === true
                        ? <Message className='red center'>
                            <p>
                                There was a problem sending the information. Please check your email and try again. 
                            </p>
                        </Message>
                        : null
                    }
            
                    { message }
                    <br/>
                    <Segment basic className='user-info'>
                        <Grid stackable columns={2}>
                            <h3 className='primary-text'>Contact Information</h3>
                            <Grid.Row>
                                <Grid.Column>
                                    <label className='input-label'>First Name</label>
                                    <br />
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Name' 
                                        value={ this.state.user.firstName }
                                        onChange={this.onNameChange.bind(this)} 
                                        className={this.state.nameError ? 'error' : ''} 
                                        ref='name'/>
                                </Grid.Column>
                            
                                <Grid.Column>
                                    <label className='input-label'>Last Name</label>
                                    <br />
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Surname' 
                                        value={ this.state.user.lastName }
                                        onChange={this.onSurnameChange.bind(this)} 
                                        className={this.state.surnameError ? 'error' : ''} 
                                        ref='surname'/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <label className='input-label'>Email</label>
                                    <br />
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Email' 
                                        value={ this.state.user.email }
                                        onChange={this.onEmailChange.bind(this)} 
                                        className={this.state.emailError ? 'error' : ''} 
                                        ref='email'/>
                                </Grid.Column>
                                <Grid.Column>
                                    <label className='input-label'>Cellphone Number</label>
                                    <br />
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Cellphone Number' 
                                        value={ this.state.user.cellphone }
                                        onChange={this.onCellphoneChange.bind(this)} 
                                        className={this.state.cellphoneError ? 'error' : ''} 
                                        ref='cellphone'/>
                                </Grid.Column>
                            </Grid.Row>
                            <h3 className='primary-text'>Billing Information (Optional)</h3>
                            <Grid.Row>
                                <Grid.Column>
                                    <label className='input-label'>Business/Trading Name</label>
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Business/Trading Name' 
                                        value={ this.state.user.businessName }
                                        onChange={this.onBusinessNameChange.bind(this)} />
                                </Grid.Column>
                                <Grid.Column>
                                    <label className='input-label'>VAT Number</label>
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='VAT Number' 
                                        value={ this.state.user.vatNumber }
                                        onChange={this.onVatNumberChange.bind(this)} />
                                </Grid.Column>

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <label className='input-label'>Business Address</label>
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Business Address' 
                                        value={ this.state.user.businessAddress }
                                        onChange={this.onAddressChange.bind(this)} />
                                </Grid.Column>

                            </Grid.Row>
                            <h3 className='primary-text'>Other (Optional)</h3>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <label className='input-label'>Sales Executive Code</label>
                                    <HelpTip tooltip={
                                        <span>
                                            If any of the sales executives at d6 Media helped you with this process please fill in the code they have provided.
                                        </span>
                                    }/>
                                    <br />
                                    <Input 
                                        disabled={ this.state.loading }   
                                        placeholder='Sales Executive Code' 
                                        value={ this.state.user.salesExecutiveCode }
                                        onChange={this.onSalesCodeChange.bind(this)} 
                                        ref='salesExecutiveCode'/>
                                </Grid.Column>

                            </Grid.Row>
                            
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <div className='center'>
                                        <Button  loading={ this.state.loading && !error } className={'d6-media-green' + 
                                            (this.state.emailError ||
                                                this.state.nameError ||
                                                this.state.surnameError ||
                                                this.state.cellphoneError || this.state.loading ? ' disabled' : '')} onClick={this.onSendClick.bind(this)}>{ sendButtonCaption }</Button>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Modal.Content>
           </Modal>




        )
    }
}

export default UserInfoModal
