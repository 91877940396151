import React, { Component } from 'react'
import queryString from 'query-string'
import '.././styles/custom.css'
import { Button, Message, Icon, Segment } from 'semantic-ui-react'
import { isUndefined } from 'lodash'
import { getPeachPaymentsStatus, updatePayment } from '../api'


class PPPayResolver extends Component {

    constructor(props) {
        super()
        this.state = {

            errMessage: null
        }
    }

    componentDidMount() {

        var searchObj = queryString.parse(this.props.location.search)
        this.getPeachPaymentsStatus(searchObj.resourcePath)
    }

    async getPeachPaymentsStatus(uri) {

        getPeachPaymentsStatus(uri).then(response => {

            var successCodes = ['000.000.000',
            '000.400.000',
            '000.400.010',
            '000.400.020',
            '000.400.040',
            '000.400.060',
            '000.400.090',
            '000.100.110',
            '000.100.111',
            '000.100.112']

         
            if(response.data && 
                response.data.result && 
                response.data.result.code )
            {
                if(successCodes.includes(response.data.result.code))
                {
                    localStorage.setItem("master", true)
                    localStorage.setItem("processCompleted", true)
                    localStorage.setItem("showStepForm", false)
                    localStorage.setItem("showBusinessType", true)
                    localStorage.setItem("showLanding", false)
                    localStorage.setItem("submissionId", JSON.stringify(null))

                    var payload = {
                        // If the Registration ID field was not given, assume it's a payment, otherwise a card registration (for R 0 payments)
                        'registration_id': isUndefined(response.data.registrationId) ? response.data.id : response.data.registrationId,
                        'card_last_4_digits': response.data.card.last4Digits
                    }

                    updatePayment(localStorage.getItem("slug"), localStorage.getItem("selectedSubscriptionId"), payload).then(response => {

                        localStorage.removeItem('selectedSubscriptionId')
                        window.location.href = '/'
                    }).catch((err) => {
                    
                        localStorage.removeItem('selectedSubscriptionId')
                        window.location.href = '/'
                    })
                }
                else
                {
                    this.setState({
                        errMessage: response.data.result.description
                    })

                    this.setRedirectInterval()
                }
            }
            else  {
                this.setState({
                    errMessage: "An error occured while trying to process the payment"
                }) 

                this.setRedirectInterval()
           }
        

        }).catch((err) => {

           if(err.response)
           {
               var response = JSON.parse(err.response.request.response)

                this.setState({
                    errMessage: response.result.description
                })

           }
           else  {
                this.setState({
                    errMessage: "An error occured while trying to process the payment"
                }) 
           }       
           
           this.setRedirectInterval()
        })
    }

    setRedirectInterval = () => {
        var counter = 5
        var interval = setInterval(function() {
            counter--
            // Display 'counter' wherever you want to display it.

            this.setState({
                counter
            })
            if (counter === 0) {
                // Display a login box
                clearInterval(interval);
            }
        }.bind(this), 1000)

        setTimeout(() => {
             localStorage.setItem("paymentID", null)
             window.location.href = '/'
        }, 5000)

    }

    render() {

        return (
            <Segment>
            
                {
                    !this.state.errMessage 
                    ?   <Message icon>
                            <Icon name='circle notched' loading />
                            <Message.Content>
                                <Message.Header>Just one second</Message.Header>
                                Payment Processing...
                            </Message.Content>
                        </Message>
                    : <Message negative>
                            <Message.Header>Error Processing Payment</Message.Header>
                            <p>{this.state.errMessage}</p>
                            <p>You will be redirected to the payment page in {this.state.counter} seconds. If you are not automatically redirected please <a href={process.env.REACT_APP_URL}>click here</a>.
                            </p>

                        </Message>
                } 
            </Segment>
        
        )
    }
}

export default PPPayResolver
