import React, { Component } from 'react'
import { ChromePicker }     from 'react-color'
import Slider               from 'react-slick'
import { Grid, Checkbox, Button, Segment, Loader, Icon,Rail, List } from 'semantic-ui-react'

import _, { map } from 'lodash'
import PaymentOption from './PaymentOption'

class PaymentOptionSelection extends Component {

    constructor(props) {
        super()
           
        this.state = {
          
            selected: props.paymentOptions? props.paymentOptions[0].id : -1
        }

    }

    onPaymentOptionSelect = (id) => {
        this.setState({
            selected : id
        })
        
        this.props.onPaymentOptionSelect(id)
    }
  
    componentDidUpdate() {

        if(this.props.paymentOptions && this.state.selected == -1)
        {
            this.setState({

                selected: this.props.paymentOptions[0].id 
            })
        }
    }

    render() {

        const { loading } = this.props 
        return (
            <Segment loading={loading}>
                <Grid stackable className='payment-options'> 
                {
                    this.props.paymentOptions
                    ? <Grid.Row columns={this.props.paymentOptions.length}>
                        {
                            map(this.props.paymentOptions, (item, key) => {

                                return (
                                    <Grid.Column>
                                        <PaymentOption
                                            selected={item.id == this.state.selected}
                                            item={item}
                                            onClick={this.onPaymentOptionSelect.bind(this, item.id)}/>
                                    </Grid.Column>
                                )
                    
                            })

                        }
                    </Grid.Row>
                    : null
                }
                </Grid>
            </Segment>
            
        )
    }
}


export default PaymentOptionSelection