import React, { Component } from 'react'
import '.././styles/custom.css'
import { Segment, Grid, Card, Message, Button, Icon } from 'semantic-ui-react'
import StepForm from '../views/StepForm'
import Completed from '../views/Completed'
import EmailModal from '../views/EmailModal'
import { postSendEmail } from '../api'
import ReactGA from 'react-ga'
import UserInfoModal from '../views/UserInfoModal'
import { createSubmission } from '../api'
import {assign} from 'lodash'
import CampaignExpiredMessage from '../components/CampaignExpiredMessage'
import ForOhFour from '../views/ForOhFour'
import moment from 'moment-business-days'

class Master extends Component {

    constructor(props) {
        super()

        let currentDate = moment().format('YYYY-MM-DD')
        let currentMaxPaymentDate = moment(localStorage.getItem("maxPaymentDate")).format('YYYY-MM-DD')
        // If an existing campaign expired
        let campaignExpired = moment(currentDate).isAfter(moment(currentMaxPaymentDate))

        if (campaignExpired)
        {
            // Campaign expired. Reset to defaults
            this.resetLocalStorage(props)
        }

        this.initLocalStorage(props)

        this.state = {
            showDesktopMsg: this.checkIfMobile(),
            processCompleted: localStorage.getItem("processCompleted") === "true",
            openEmailModal: false,
            emailSentSuccessfully: null,
            showStepForm: localStorage.getItem("showStepForm") === "true",
            showLanding: localStorage.getItem("showLanding") === "true",
            openUserInfoModal: false,
            submissionId: JSON.parse(localStorage.getItem("submissionId")),
            showCampaignExpiredMsg: campaignExpired
        }
        this.mountGA()
    }

    mountGA = () => {

        var script = document.createElement("script")
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.REACT_APP_GA_ID
        script.async = true
        document.getElementsByTagName("head")[0].appendChild(script)

        script = document.createElement("script")
        script.innerHTML = "window.dataLayer = window.dataLayer || [];" +
        " function gtag(){dataLayer.push(arguments);} " + 
        " gtag('js', new Date()); " +
        " gtag('config', '" + process.env.REACT_APP_GA_ID + "'); "
        document.getElementsByTagName("head")[0].appendChild(script)
    
    }

    initLocalStorage = (props) => {

        if(localStorage.getItem("master") == null)
        {
            //set init local storage
            localStorage.setItem("master", true)
            localStorage.setItem("processCompleted", false)
            localStorage.setItem("showStepForm", false)
            localStorage.setItem("showLanding", false)
            localStorage.setItem("submissionId", JSON.stringify(null))
            localStorage.setItem("campaignExpired", false)
        }
    }

    resetLocalStorage = (props) => {
        localStorage.clear()

        localStorage.setItem("master", true)
        localStorage.setItem("processCompleted", false)
        localStorage.setItem("showStepForm", true)
        localStorage.setItem("showLanding", false)
        localStorage.setItem("submissionId", JSON.stringify(null))
    }

    dismissDesktopMsg = () => {
        this.setState({
            showDesktopMsg: false
        })
    }

    dismissCampaignExpiredMsg = () => {
        this.setState({
            showCampaignExpiredMsg: false
        })
    }    

    onProcessCompleted = () => {

        this.setState({

            processCompleted: true
        })

        localStorage.setItem("processCompleted", true)
        localStorage.clear()
    }

    onCloseEmailModal = () => {

        this.setState({
            openEmailModal: false,
            showDesktopMsg: false
        })
    }

    onSendEmail = (email, url, onError) => {

        this.sendEmail(email, url, onError)
        this.setState({
            email
        })
    }

    onOpenEmailModal = () => {

        this.setState({
            openEmailModal: true,
            emailSentSuccessfully: null
        })
    }

    async sendEmail(email, url, onError) {
        const payload = {
            email: email,
            url: url,
        }

        await postSendEmail(payload).then((response) => {

            this.setState({

                emailSentSuccessfully: true,
                openEmailModal: false,
                showDesktopMsg: false
            })
            

            setTimeout(
                function(){ 
                    this.setState({

                        emailSentSuccessfully: null
                    }) 
                }.bind(this), 3000
            )


        }).catch((error) => {

            this.setState({

                emailSentSuccessfully: false
            })

            onError()

        })

    }

     /* CREATE SUBMISSION */
     async createSubmission(user, onError) {

        this.setState({
            error: null
        })

    //    var payload = {
    //         email: user.email,
    //         first_name: user.firstName,
    //         last_name: user.lastName,
    //         cellphone: user.cellphone,
    //     }

    //     localStorage.setItem("user", JSON.stringify(user))

    //     if(user.salesExecutiveCode !== '')
    //     {
    //         payload = assign(payload, { sales_code: user.salesExecutiveCode })
    //     }

    //     if(user.company !== '')
    //     {
    //         payload = assign(payload, { company: user.company })
    //     }

        // await createSubmission(payload).then((response) => {

        //     this.setState({
        //         submissionId : response.data.id,
        //         openUserInfoModal: false,
        //     })

        //     localStorage.setItem("submissionId", response.data.id)

        //     this.state.userDetailsFilledInFunc()

        // }).catch((error) => {

        //     onError()
        // })

        this.setState({
            openUserInfoModal: false,
        })

        this.state.userDetailsFilledInFunc()
     }

    checkIfMobile = () => {

        var isMobile = false //initiate as false
        //device detection
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
            isMobile = true
        }

        return isMobile
    }


    onBasicContinue = () => {

        this.setState({
            
            showStepForm: true,
        })

        localStorage.setItem("showStepForm", true)
    }

    onCreateBannerClick = () => {

        this.setState({
            showStepForm: true,
            showLanding: false
        })

        localStorage.setItem("showStepForm", true)
    }

    continueToProcess = (user, onError) => {

        this.createSubmission(user, onError)

    }

    onHelpClicked = () => {

        window.open('https://d6.co.za/media/want-to-connect/lead/', 'target-new')

    }

    onResetClick = () => {

        this.resetLocalStorage()

        window.location.reload()

    }

    render() {
        return (

            <div id='background' className={(this.state.showStepForm ? '' : ' landing')}>
                {
                    this.state.showCampaignExpiredMsg
                    ? <CampaignExpiredMessage
                        dismissCampaignExpiredMsg={this.dismissCampaignExpiredMsg.bind(this)} />
                    : null
                }
                <Grid centered id='main-content'>
                     <Grid.Row centered className='flat'>
                            <Grid.Column className='flat' mobile={16} tablet={14} computer={13}>

                                <Card className={'full-width' + (this.state.showStepForm ? '' : ' landing')}>
                                    <Card.Content>
                                        {   
                                            ! this.state.processCompleted && this.state.showStepForm
                                            ?   <div>
                                                    <img className='top-logo' src='/images/logo_horizontal.png' />
                                                
                                                    <Button style={{ float: 'right' }} className={'d6-media-green'} size='mini' icon labelPosition='left' onClick={this.onResetClick.bind(this)}>
                                                        <Icon name='undo alternate' />
                                                        Reset Page
                                                    </Button> 
                                        
                                                    <Segment className='welcome-message'>
                                                        <h2>Welcome to Local Listings! </h2>
                                                        <p>Please follow these quick and easy steps to list your business as a Local Listing.</p>   
                                                    </Segment>

                                                   
                                                </div>
                                            : null
                                        }

                                        {
                                            this.state.processCompleted
                                            ? <Completed
                                                message='Your listing will show once payment reflects and your listing has been approved by d6.'/>
                                            
                                            : this.state.showStepForm

                                                    ? <Grid columns={2} id="form-layout" stackable> 
                                                            <Grid.Row columns={1}> 
                                                                <Grid.Column> 
                                                                    {/* Step Form */}
                                                                    <StepForm 
                                                                        submissionId={ this.state.submissionId }
                                                                        onProcessCompleted={ this.onProcessCompleted.bind(this) } />
                                                                </Grid.Column> 
                                                            </Grid.Row>
                                                        </Grid>
                                                
                                                    : <Grid columns={2} id="form-layout" stackable>

                                                        <Grid.Row >

                                                            <Grid.Column verticalAlign='middle'>
                                                                <Segment basic textAlign='center'>
                                                                    <h2>Welcome to Local Listings Self Onboarding!</h2>
                                                                    Follow a few easy steps to list your business in <strong>d6 Connect</strong>.
                                                                    </Segment>
                                                            </Grid.Column>

                                                            <Grid.Column>
                                                                <Segment basic textAlign='center'>
                                                                    <img src='/images/app_layout.png' id="app-layout-img" />
                                                                    <br/>
                                                                    <br/>
                                                                    <p>Please note your listing will be displayed in the d6 Connect app under <strong className='primary-text'>More &gt; Local Listings &gt; Find a Listing</strong></p>  
                                                                
                                                                </Segment>
                                                            </Grid.Column>

                                                        </Grid.Row>

                                                        <Grid.Row centered>
                                                            <Button className='d6-media-green' onClick={ this.onCreateBannerClick.bind(this) }>Create Your Listing</Button>

                                                        </Grid.Row>

                                                        <Grid.Row centered>
                                                            <Grid.Column>
                                                                <Segment basic textAlign='center' className="full-width">
                                                                <p>For assistance please</p>
                                                                <span className='contact-us'><a target="_blank" href='https://d6.co.za/media/local-listings/'>Contact Us</a></span>
                                                                </Segment>
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                    </Grid>
                                                }

                                    </Card.Content>
                                </Card>

                            </Grid.Column>
                        </Grid.Row>
                
                </Grid>

                {this.state.showDesktopMsg
                    ? <div className='to-desktop-message'>
                        <Message className='primary olive center'>
                            <p>We recommend that you do this on your <strong>desktop computer</strong> for convenience. </p>
                            <Segment basic>
                                <h4>Type in this url: <br /><strong>{ process.env.REACT_APP_URL }</strong></h4>
                            </Segment>
                            <Button className='d6-media-green' onClick={ this.onOpenEmailModal.bind(this) }>Email Me a Link</Button>
                            <div className='align-right'>
                                <a onClick={this.dismissDesktopMsg.bind(this)}>Dismiss</a>
                            </div>
                        </Message>
                    </div>
                    : null
                }

                 <EmailModal 
                    open={ this.state.openEmailModal }
                    message={
                        <div>
                            <h3>Send the desktop link to:</h3>
                        </div>
                    } 
                    onSendClick={ this.onSendEmail.bind(this) }
                    onCancelClick={ this.onCloseEmailModal.bind(this) } 
                    sendButtonCaption='Send'
                    cancelButtonCaption='Cancel'
                    error={ this.state.emailSentSuccessfully === false }/>

                { this.state.emailSentSuccessfully === true 
                    ? <Message className='primary olive toast floating'>Email sent!</Message>
                    : null
                }

            
                <Button onClick={this.onHelpClicked.bind(this)} size='large' circular icon='help' className='d6-media-green floating-help-button' />

            </div>
        )
    }
}

export default Master
