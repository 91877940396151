import React, { Component } from 'react'
import '.././styles/custom.css'
import ForOhFour from '../views/ForOhFour'
import Master from './Master'
import Eol from './Eol'
import PPPayResolver from '../views/PPPayResolver'
import PPUpdateResolver from '../views/PPUpdateResolver'
import SubscriptionManagment from './SubscriptionManagment'
import {
    Router,
    Redirect,
    Route,
    Switch
  } from 'react-router-dom'

import history from "../history"

class SORouter extends Component {

    constructor(props) {
        super()

        this.state = {
         
            routes : [
        
                { 
                  path: '/',
                  exact: true,
                  main: Eol,
                  condition: true,
                },
                { 
                    path: '/peach-payments/payed',
                    exact: true,
                    main: PPPayResolver,
                    condition: true,
                    redirect: '/'
                  },
                  { 
                    path: '/peach-payments/updated',
                    exact: true,
                    main: PPUpdateResolver,
                    condition: true,
                    redirect: '/'
                  },
                  { 
                    path: '/subscriptions',
                    exact: false,
                    main: SubscriptionManagment,
                    condition: true,
                    redirect: '/'
                  }
            ]
        }
    }


    render() {
        return (

            <div>
                <Router history={history}>
                    <Switch>
                        
                        {this.state.routes.map((route, index) => (
                            // Render more <Route>s with the same paths as
                            // above, but different components this time.

                            route.condition 
                            ?  <Route 
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.main}/>
                            :
                            <Route 
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={() => (<Redirect to={route.redirect} />)}/>

                        ))}

                        <Route 
                            path="*"
                            component={ForOhFour}/>

                    </Switch>
                </Router>

            </div>
        )
    }
}

export default SORouter
