import React, {Component} from "react"
import { Icon, Message } from 'semantic-ui-react'

class HelpTip extends Component {
  
  
  render() {
   
     const { message } = this.props

     return ( 
     
      <div className="ui left corner labeled input full-width contact-us-block">
          <div className="ui label label left corner">
              <i aria-hidden="true" className="help big icon"></i>
          </div>
          <Message className='help olive center'>
              <h2>Need Help?</h2>
              {message}
          </Message>
      </div>
    )
   }
   
}

export default HelpTip