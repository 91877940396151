import React, { Component } from 'react'
import '.././styles/custom.css'
import { Segment, Grid, Card, Header, Message, Icon } from 'semantic-ui-react'
import Subscription from '../views/Subscription'
import HelpMessage from '../components/HelpMessage'
import { map, isUndefined } from 'lodash'
import {getSubscriptions} from '../api'
import PeachPaymentModal from '../views/PeachPaymentsModal'
import {getCheckoutIDUpdatePayment, cancelSubscription} from '../api'

class SubscriptionManagement extends Component {

   
    constructor(props) {
        super()

        this.state = {
            subscriptions : [],
            fetching: false
        }
    }

    onCheckoutIdReceived = (checkoutId) => {

        this.setState({
             openPeachModal: true,
             checkoutId
        })
    }

    onPeachModalCancel = () => {
        this.setState({
            openPeachModal: false
       })

    }

    async getSubscriptions(slug) {
        this.setState({
             fetching: true
        })

    
        await getSubscriptions(slug).then((response) => {

            var subscriptions = response.data

            if(!isUndefined(subscriptions)) {
                this.setState({
                    fetching: false,
                    subscriptions
                })
            }

            return subscriptions

        }).catch((error) => {

            this.setState({
                fetching: false,
                subscriptions: []
            })

        })
    }

    async onUpdatePayment(id) {
        this.setState({
            loading: true
        })

        await getCheckoutIDUpdatePayment(this.state.slug, id).then((response) => {

            var checkoutId =  response.data.peach_checkout_id

            this.setState({
                loading: false,
                checkoutId
            })

            this.onCheckoutIdReceived(checkoutId)

            return checkoutId

        }).catch((error) => {

            this.setState({
                loading: false,
                checkoutId: null
            })

        })
    }

    async onCancelSubscription(id) {
        this.setState({
            loading: true
        })

        await cancelSubscription(this.state.slug, id).then((response) => {

            this.setState({
                loading: false,
                err: null
            })

            window.location.href = '/subscriptions/' + this.state.slug

        }).catch((error) => {

            this.setState({
                loading: false,
                err: error.response.data.error
            })

        })
    }

    componentDidMount() {
        
        var slug = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
        
        this.setState({
            slug
        })

        localStorage.setItem("slug", slug)

        this.getSubscriptions(slug)
    }

    render() {
        return (

            <div id='background'>
                
                <Grid centered id='main-content'>
                     <Grid.Row centered className='flat'>
                            <Grid.Column className='flat' mobile={16} tablet={14} computer={13}>

                                <Card className={'full-width'}>
                                    <Card.Content>
                                       <div>
                                            <img className='top-logo' src='/images/logo_horizontal.png' />
                                        </div>
                                        <Segment>
                                            <h2>Local Listing Breakdown</h2>
                                            <p>You can manage your subscriptions and payment details here.</p> 
                                        </Segment>

                                        {
                                            this.state.err
                                            ?<Message negative>
                                                <Message.Header>Error Occured</Message.Header>
                                                <p>{this.state.err}</p>
                                            </Message>
                                            : null
                                        }

                                        { this.state.subscriptions.length > 0 
                                            ? <Segment padded loading={this.state.loading}>

                                                <h3>Your Subscriptions</h3>

                                                {
                                                map(this.state.subscriptions, (item, key) => {

                                                    return (
                                                        <Subscription
                                                            onUpdatePayment={this.onUpdatePayment.bind(this)}
                                                            onCancelSubscription={this.onCancelSubscription.bind(this)}
                                                            key={item.id}
                                                            subscription={item}
                                                            slug={this.state.slug}
                                                        />
                                                    )
                                        
                                                })
                                            }

                                            <br/>

                                            <HelpMessage
                                                message={
                                                    <div>
                                                        <p className='center'>
                                                            For assistance please 
                                                        </p>
                                                        <span className='contact-us'><a target="_blank" href='https://d6.co.za/media/local-listings/'>Contact Us</a></span>
                                                    </div>
                                                }/>

                                            </Segment>
                                                :<Segment textAlign='center' placeholder>
                                                    {
                                                            this.state.fetching
                                                        ? <Header icon>
                                                            <Icon name='hourglass outline' />
                                                            Fetching Subscriptions
                                                        </Header>
                                                        : null
                                                    }

                                                    <Segment loading={this.state.fetching}  textAlign='center' placeholder>
                                                        {
                                                            this.state.fetching
                                                            ? null
                                                            :<Header icon>
                                                                <Icon name='calendar times outline' />
                                                                No Subscriptions Found
                                                            </Header>
                                                        }
                                                    </Segment>
                                                </Segment>
                                        }   
                                       
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                                       
                        </Grid.Row>
                
                </Grid>

                {
                        this.state.openPeachModal
                        ? <PeachPaymentModal 
                            open={this.state.openPeachModal} 
                            checkoutId={this.state.checkoutId}
                            onCancelClick={this.onPeachModalCancel.bind(this)}
                            resolveUrl='/peach-payments/updated'/>
                        : null

                    }
            </div>
        )
    }
}

export default SubscriptionManagement
