import React, { Component } from 'react'
import ListingPreview from './ListingPreview'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class ListingCol extends Component {
    
    
    render() {

        return (
            <ListingPreview
            logo={this.props.listing.logo}
            businessName={this.props.listing.businessName}
            contactNumber={this.props.listing.contactNumber}
            email={this.props.listing.email}
            address={this.props.listing.address}
            website={this.props.listing.website}
            description={this.props.listing.description}/>
        )
    }
}

export default ListingCol