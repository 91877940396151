import React, { Component } from 'react'
import '.././styles/custom.css'
import { Segment, Grid, Card, Message, Button, Icon } from 'semantic-ui-react'

const Eol = (props) => (
    <div id='background' className={ ' landing' }>
        <Grid centered id='main-content'>
            <Grid.Row centered className='flat'>
                <Grid.Column className='flat' mobile={16} tablet={14} computer={13}>
                    <Card className={ 'full-width' }>
                        <Card.Content>
                            <div>
                                <img className='top-logo' src='/images/logo_horizontal.png' />
                            </div>
                            <Segment textAlign='center' placeholder>
                                <img className='eol-banner' src='/images/ooops.png' />
                            </Segment>
                            <Segment basic className='center'>
                                <h1>Local Listings is no longer available but we have good news!</h1>
                                <h2>We can still help you drive a niched audience of new prospects to your business at affordable prices.</h2>
                                <span className='contact-us'><a href='https://d6.co.za/media/'>Learn more here</a></span>
                            </Segment>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
)

export default Eol
