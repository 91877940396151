import React, { Component } from 'react'
import '.././styles/custom.css'
import { List, Icon, Message, Segment, Modal, Grid, Dropdown } from 'semantic-ui-react'
import InputSlider from '../components/Slider'
import SelectedBox from '../components/SelectedBox'
import SchoolSelection from './SchoolSelection'
import { reject, map, concat, isUndefined, find, sumBy, size, assign } from 'lodash'
import DatePicker from '../components/DatePicker'
import HelpMessage from '../components/HelpMessage'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment-business-days'
import ReactGA from 'react-ga'
import { getSelectedSchool, getCategories } from '../api';


class WhatWhereExp extends Component {

    constructor(props) {
        super()
       
        this.initLocalStorage(props)

        var selectedSchools = localStorage.getItem("selectedSchools")
        this.state = {
            openSchoolSelection: false,
            selectedSchools: JSON.parse(selectedSchools),
            userCount: localStorage.getItem("userCount"),
            category: JSON.parse(localStorage.getItem("category")),
            calculating: true,
            subcategories: [],
            subcategoryMap: {},
        }

        this.getCategories()

        var schoolIds = map(JSON.parse(selectedSchools), 'id')

        props.onUserCountChange(this.state.userCount, schoolIds, this.onCalculatingStop.bind(this))
    }

    initLocalStorage = (props) => {

        if(localStorage.getItem("WhatWhere") == null) {
            //set init local storage
            localStorage.setItem("WhatWhere", true)
            localStorage.setItem("userCount", props.userCount)
            localStorage.setItem("selectedSchools", JSON.stringify([]))
        }
    }

    onCalculatingStop = () => {
        this.setState({

            calculating: false
        })
    }

    onCategorySelected = (e, { value }) => {

        window.gtag('event', 'category_selected', {
            'event_category': 'What & Where',
            'event_label': 'Category Selected',
          })

        var category = {
            "value": value,
            "name": e.target.textContent
        }

        localStorage.setItem("category", JSON.stringify(category))

        this.setState({
            category: category,
            subcategory: null,
            subcategories: this.state.subcategoryMap[value]
        })

        this.props.onCategorySeleted(category)
    }

    onSubcategorySelected = (e, { value }) => {

        window.gtag('event', 'subcategory_selected', {
            'event_category': 'What & Where',
            'event_label': 'Subcategory Selected',
          })

        var subcategory = {
            "value": value,
            "name": e.target.textContent
        }
    
        localStorage.setItem("subcategory", JSON.stringify(subcategory))

        this.setState({
            subcategory: subcategory
        })

        this.props.onSubcategorySeleted(subcategory)
    }

    
    calUserCount = (selectedSchools) => {

        var userCount = sumBy(selectedSchools, 'num_mobile_users')

        localStorage.setItem("userCount", userCount)

        var schoolIds = map(selectedSchools, 'id')

        this.props.onUserCountChange(userCount, schoolIds, this.onCalculatingStop.bind(this))
        this.setState({
            userCount,
            calculating: true
        })
    }

    onOpenSchoolSelection = () => {

        this.setState({
            openSchoolSelection: true,
        })
    }

    onCloseSchoolSelection = () => {

        this.setState({
            openSchoolSelection: false,
        })
    }


    /* Callback from the schools selection page */
    onSchoolsSelected = (selectedSchools) => {

        this.setState({

            openSchoolSelection: false,
            selectedSchools
        })

        localStorage.setItem("selectedSchools", JSON.stringify(selectedSchools))

        this.calUserCount(selectedSchools)

    }

    /* Gets a formatted list of selected schools to show in the selected box */
    getSelectedSchools = (schools) => {

        var selection = []

        map(schools, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

    
        return selection

    }

    onContactUsClick = () => {
        
        return true
    }

    /* Gets called when a school is selected or deselected */
    onSchoolSelectionChange = (school) => {

        var _selectedSchools = concat([], this.state.selectedSchools)


        if (!isUndefined(find(_selectedSchools, ['id', school.id]))) {
            _selectedSchools = reject(_selectedSchools, ['id', school.id])
        }
        else {
            _selectedSchools = concat(_selectedSchools, school)
        }

        this.setState({

            selectedSchools: _selectedSchools
        })

        localStorage.setItem("selectedSchools", JSON.stringify(_selectedSchools))


        this.calUserCount(_selectedSchools)

    }

    async getCategories() {

        await getCategories().then(function (response) {

            var categories = []
            var subcategoryMap = {}

            map(response.data, (item) => {

                categories = concat(categories, [

                    { 
                        key: item.id, 
                        text: item.name, 
                        value: item.id,
                    },
                ])

                var subcategories = []

                map(item.sub_categories, (subcategory) => {
                    subcategories = concat(subcategories, [

                        { 
                            key: subcategory.id, 
                            text: subcategory.name, 
                            value: subcategory.id,
                        },
                    ])

                })

                subcategoryMap = assign(subcategoryMap, {
                    [item.id] : subcategories
                })
            })

            this.setState({
                categories,
                subcategoryMap,
            })

            if(this.state.category)
            {
                this.setState({
                    subcategories: subcategoryMap[this.state.category.value]
                }, ()=> {

                    this.setState({
                        subcategory: JSON.parse(localStorage.getItem("subcategory")),
                    })
                })
            }


        }.bind(this)).catch((error) => {

        })

    }

    async getSelectedSchool(keywords) {


        await getSelectedSchool(keywords).then((response) => {

            this.setState({
                selectedSchools: response.data
                
            },()=>{
                this.calUserCount(this.state.selectedSchools)
            })

        }).catch((error) => {

        })

    }

    componentDidMount =  () => {

        try {
            if(this.state.selectedSchools.length === 0)
            {
                const b = new URLSearchParams(window.location.search)
                if(b.has('mk') && !b.get('mk').includes("{{"))
                {
                    const keywords = b.get('mk')
                    this.getSelectedSchool(keywords)
                }
            }
        }
        catch(e) {}
    }

    render() {

        const { amount } = this.props

        return (
            <div className='when-where'>

                <div className='expanded'>

                    <List>

                           <List.Item >

                                <List.Content>
                                    <List.Header><Icon name='circle' size='small' /> Classify Your Business</List.Header>
                                  
                                    <List.Description>  
                                    
                                    <br/>
                                        {/* <strong className='primary-text'>{this.state.duration + ' days'}</strong></p> */}

                                        <Grid stackable>
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <label className='input-label'>Category</label>
                                                    <br />
                                                    <br />
                                                    <Dropdown
                                                        placeholder='Select a Category'
                                                        fluid
                                                        selection
                                                        value={this.state.category? this.state.category.value : null}
                                                        options={this.state.categories ? this.state.categories : []}
                                                        onChange={this.onCategorySelected.bind(this)}
                                                    />
                                                </Grid.Column>
                                                { this.state.category
                                                    ?<Grid.Column>
                                                        <label className='input-label'>Subcategory</label>
                                                        <br />
                                                        <br />
                                                        <Dropdown
                                                            disabled={this.state.category == null}
                                                            placeholder='Select a Subcategory'
                                                            fluid
                                                            value={this.state.subcategory? this.state.subcategory.value  : null}
                                                            selection
                                                            options={this.state.subcategories}
                                                            onChange={this.onSubcategorySelected.bind(this)}
                                                        />
                                                    </Grid.Column>
                                                    :null
                                                }
                                            </Grid.Row>
                                        </Grid>
                                    
                                    </List.Description>
                                </List.Content>
                        </List.Item>
                        <List.Item>

                            <List.Content>
                                    <List.Header> <Icon name='circle' size='small' /> 
                                        Users 
                                    </List.Header>
                                    <List.Description>
                                
                                        {this.state.category && this.state.subcategory
                                            ?<div>
                                                <p>List on the following schools:</p>
                                                <SelectedBox 
                                                    onClick={this.onOpenSchoolSelection.bind(this)} 
                                                    selection={this.getSelectedSchools(this.state.selectedSchools)} 
                                                    onRemoveItem={this.onSchoolSelectionChange.bind(this)} />

                                                <h4>Up to {this.state.userCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} users will be reached</h4>
                                            </div>
                                            : <label className='red'>Please classify your business first.</label>
                                        }   

                                    </List.Description>
                               
                            </List.Content>

                        </List.Item>
                        <List.Item >
                            <List.Content>
                                <List.Header><Icon name='circle' size='small' /> Total Cost</List.Header>
                                <List.Description>
                                    <Segment textAlign='center' loading={this.state.calculating} >
                                        <h2>{ 
                                                amount.indexOf('/') == -1 
                                                ? amount
                                                : amount.substr(0, amount.indexOf('/')) 
                                            }
                                            {
                                                amount.indexOf('/') != -1 
                                                ? <span className='per-month'>{ amount.substr(amount.indexOf('/')) }</span>
                                                : null
                                            }
                                        </h2>
                                        <label className='primary'>VAT incl.</label>
                                        <br/>
                                        <br/>
                                        <label className='primary' style={{fontSize:'12px'}}>50% of the above amount will be rewarded to your selected schools.</label>
                                    </Segment>
                                </List.Description>
                            </List.Content>
                            <HelpMessage
                                message={
                                    <div>
                                        <p className='center'>
                                            For assistance please
                                        </p>
                                        <span className='contact-us'><a onClick={this.onContactUsClick.bind(this)} target="_blank" href='https://d6.co.za/media/local-listings/'>Contact Us</a></span>
                                    </div>
                                }/>
                        </List.Item>

                    </List>

                </div>

                { this.state.openSchoolSelection
                    ? <Modal open={this.state.openSchoolSelection} onClose={this.close} size='fullscreen'>
                        <Modal.Content>
                            <SchoolSelection
                                userCount={this.state.userCount}
                                amount={amount}
                                onContinueClick={this.onSchoolsSelected.bind(this)}
                                onCancelClick={this.onCloseSchoolSelection.bind(this)}
                                selectedSchools={this.state.selectedSchools}
                                subcategoryId={this.state.subcategory.value}
                                planId={this.props.planId}
                            />
                        </Modal.Content>
                    </Modal>
                    : null
                }
            </div>
        )
    }
}

export default WhatWhereExp

