import React, { Component } from 'react'
import { Search } from 'semantic-ui-react'
import { debounce } from 'lodash';

class SearchBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            focused: false
        }

    }

    doSearch = (searchString) => {
        this.props.onSearchChange(searchString)
    }

    debouncedSearch = debounce(this.doSearch, 500)

    handleKeyUp(e) {
        this.debouncedSearch(e.target.value);
    }

    onFocus() {

        this.setState({

            focused: true

        })
    }

    onBlur() {

        this.setState({

            focused: false

        })
    }

    handleKeyDown(e) {
        if (e.which === 13) e.preventDefault()
    }

    render() {

        const { loading, placeholder, value, disabled } = this.props
        return (

            <div className="ui fluid search primary-search">
                <div className="ui input">
                    <Search loading={loading} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} ref="search" type="text" placeholder={placeholder ? placeholder : "Search..."} defaultValue={value} className="prompt"
                        onKeyUp={this.handleKeyUp.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} disabled={disabled}
                        showNoResults={false} />
                </div>
                <br />
            </div>
        )
    }
}

export default SearchBar;