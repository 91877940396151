import React, {Component} from "react"
import { Icon, Popup } from 'semantic-ui-react'

class HelpTip extends Component {
  
  
  render() {
   
     const { tooltip, size } = this.props

     return ( 
     
      <Popup
          trigger={ <Icon color="olive" size={ size } name="question circle outline"/> }
          content={ tooltip }
        />
    )
   }
   
}

export default HelpTip