import React, { Component } from 'react'
import { Grid, Segment, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import DefaultImage from '../styles/images/contact_us.png'


class ListingPreview extends Component {
    
  
    render() {

        return (
            <Segment className='listing-preview'>
                <Grid>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width="3"> 
                        <img 
                                className='listing-logo' 
                                src={ this.props.logo ? this.props.logo : DefaultImage }/>

                        </Grid.Column>
                        <Grid.Column width="10">
                            <p className='listing-name'>
                                {this.props.businessName ? this.props.businessName : "Your Business Name Here"}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    {
                        this.props.description
                        ? <Grid.Row>
                            <Grid.Column>
                                <p>
                                    {this.props.description}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        :null
                    
                    }
                   <Grid.Row>
                            <Grid.Column>
                                <Icon name='phone' />
                                <p>
                                    {this.props.contactNumber ? this.props.contactNumber : "Your Business Contact Number Here"}
                                </p>
                            </Grid.Column>
                    </Grid.Row>
                        
                    {
                        this.props.address
                        ?<Grid.Row>
                            <Grid.Column>
                                <Icon name='home' />
                                <p>
                                    {this.props.address}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        : null
                    }
                    {
                        this.props.email
                        ?<Grid.Row>
                            <Grid.Column>
                                <Icon name='envelope' />
                                <p>
                                    {this.props.email}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        : null
                    }
                    {
                        this.props.website
                        ?<Grid.Row>
                            <Grid.Column>
                                <Icon name='globe' />
                                <p>
                                    {this.props.website}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        : null
                    }
                    
                </Grid>
            
            </Segment>
        )
    }
}


export default ListingPreview