import React, { Component } from 'react'
import '.././styles/custom.css'
import { Table, Checkbox, Icon, Popup, Segment, Grid } from 'semantic-ui-react'
import { map, find, isUndefined } from 'lodash'
import PaginationDropdown from '../components/PaginationDropdown'
import PaginationNav from '../components/PaginationNav'
import { constants } from '../constants'


class SchoolTable extends Component {


    getProvinceName = (provinceId) => {
        const province = find(this.props.provinces, { 'id' : provinceId }) 

        if(province) {
            return province.name
        }     
        else {
            return ""
        }

    }

    render() {

        const { 
            schools,
            selectedSchools,
            onRowSelected,
            provinces,
            loading,
            currentPage,            // Current paginated page. 
            totalPages,             // Total number of page.
            perPage,                // How many items to show per page.
            onPageChange,           // Callback to be used when a page is changed.
            onPaginationIntervalChange,     // Callback to be used when the number of items per page is changed.
        } = this.props
        return (
            <div>
                <Segment.Group className="basic" >
                    <Segment basic floated='right'>
                        <PaginationDropdown onPaginationIntervalChange={onPaginationIntervalChange.bind(this)} perPageMenu={constants().PER_PAGE_OPTIONS} />
                    </Segment>

                    <Segment basic floated='right'>
                        <PaginationNav currentPage={currentPage} onPageChange={onPageChange.bind(this)} totalPages={totalPages} />
                    </Segment>
                </Segment.Group>


                {loading
                    ? <Segment basic loading className="table-loader"></Segment>
                    : <Table celled unstackable size='small'>

                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>School</Table.HeaderCell>
                                {/* For RON */}
                                {/* <Table.HeaderCell className='responsive-column-large'>Desktop Users</Table.HeaderCell> */}
                                <Table.HeaderCell className='responsive-column-large'>Users</Table.HeaderCell>
                                <Table.HeaderCell className='responsive-column-large'>Language</Table.HeaderCell>
                                <Table.HeaderCell className='responsive-column-large'>Province</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell className='responsive-column-small'></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                map(schools, (school, key) => {

                                    return (

                                        <Table.Row key={key}>

                                            <Table.Cell>
                                                {school.name}
                                            </Table.Cell>
                                            {/* For RON */}
                                            {/* <Table.Cell className='responsive-column-large'>
                                                {school.num_desktop_users}
                                            </Table.Cell> */}
                                            <Table.Cell className='responsive-column-large'>
                                                {school.num_mobile_users}
                                            </Table.Cell>
                                            <Table.Cell className='responsive-column-large'>

                                                { school.languages }

                                                {/* For if languages are in an array */}
                                                {/* {
                                                    map(school.languages, (language, key) => {
                                                        return language.name + (key < school.languages.length - 1 ? ', ' : '')
                                                    })
                                                } */}
                                            </Table.Cell>
                                            <Table.Cell className='responsive-column-large'>
                                                {
                                                    school.province
                                                }
                                            </Table.Cell>
                                            <Table.Cell textAlign='center'>
                                                {
                                                    school.allow_mobile_selection
                                                    ? <Checkbox checked={!isUndefined(find(selectedSchools, ['id', school.id]))} onChange={onRowSelected.bind(this, school)} />
                                                    : <p>Fully Booked</p>
                                                }
                                            </Table.Cell>
                                            <Table.Cell
                                                textAlign='center'
                                                className='responsive-column-small'>

                                                <Popup trigger={

                                                        <Icon
                                                            className='primary-text'
                                                            size='large'
                                                            name='info circle'

                                                        />
                                                    }
                                                    flowing
                                                    hoverable
                                                    on={['hover', 'click']}>
                                                        {/* For RON */}
                                                        {/* <p>Desktop Users: <strong className='primary-text'> {school.num_desktop_users}</strong></p> */}
                                                        <p>Users: <strong className='primary-text'> {school.num_mobile_users}</strong></p>
                                                        <p>Language: <strong className='primary-text'>
                                                                { school.languages }

                                                                {/* For if languages are in an array */}
                                                                {/* {
                                                                    map(school.languages, (language, key) => {
                                                                        return language.name + (key < school.languages.length - 1 ? ', ' : '')
                                                                    })
                                                                } */}
                                                            </strong>
                                                        </p>
                                                        <p>Province: <strong className='primary-text'>
                                                                {
                                                                    this.getProvinceName(school.province_id)
                                                                }
                                                            </strong>
                                                        </p>
                                                    <Grid columns={2}>
                                                        <Grid.Row>
                                                            <Grid.Column>Origin: </Grid.Column> 
                                                            <Grid.Column>
                                                                {
                                                                    school.ptalk_id 
                                                                    ? <img className='origin-logo' alt='d6 Connect Logo' src='/images/logo_ptalk.png'/>
                                                                    : <img className='origin-logo' alt='d6 Communicator Logo' src='/images/logo_d6.png'/>

                                                                }
                                                            </Grid.Column>
                                                       </Grid.Row>
                                                    </Grid>

                                                </Popup>

                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>

                    </Table>
                }
            </div>
        )
    }
}

export default SchoolTable