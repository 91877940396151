import React, { Component } from 'react'
import { Grid, Input, Button } from 'semantic-ui-react'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import _ from 'lodash'
import DefaultImage from '../styles/images/contact_us.png'
import HelpTip from '../components/HelpTip'
import validUrl from 'valid-url'
import HelpMessage from '../components/HelpMessage'
import ReactGA from 'react-ga'
import ListingPreview from './ListingPreview'


class ListingExp extends Component {
    
    constructor(props) {
        super()
       
        this.initLocalStorage(props)

        this.state = {
            logo: localStorage.getItem("logo"),
            businessName: localStorage.getItem("businessName")? localStorage.getItem("businessName") : "",
            contactNumber: localStorage.getItem("contactNumber")? localStorage.getItem("contactNumber") : "",
            email: localStorage.getItem("email"),
            address: localStorage.getItem("address"),
            website: localStorage.getItem("website"),
            description: localStorage.getItem("description")? localStorage.getItem("description") : "",
            loading: true,
            showFileError: false,
        }

        props.onUpdate('logo', this.state.logo)
    }

    initLocalStorage = (props) => {

        if(localStorage.getItem("listing") == null)
        {
            //set init local storage
            localStorage.setItem("listing", true)
            localStorage.setItem("logo", DefaultImage)
        }
    
    }


    onBusinessNameChange = (e) => {

        localStorage.setItem("businessName", e.target.value)

        this.setState({

            businessName: e.target.value
        }, ()=> {
            this.props.checkListingCompletion(this.state.businessName, this.state.contactNumber)

        })

    }

    onContactNumberChange = (e) => {

        localStorage.setItem("contactNumber", e.target.value)

        this.setState({

            contactNumber: e.target.value
        }, ()=> {

            this.props.checkListingCompletion(this.state.businessName, this.state.contactNumber)

        })


    }

    onEmailChange = (e) => {

        localStorage.setItem("email", e.target.value)

        this.setState({

            email: e.target.value,
            emailError: !this.validateEmail(e.target.value),
        }, ()=> {
            this.props.onUpdate("email", this.state.email)
            this.props.checkListingCompletion(this.state.businessName, this.state.contactNumber, this.state.emailError)
        })

    
    }

    onAddressChange = (e) => {

        localStorage.setItem("address", e.target.value)

        this.setState({

            address: e.target.value
        }, ()=> {
            this.props.onUpdate("address", this.state.address)
        })
       
    }

    onWebsiteChange = (e) => {

        localStorage.setItem("website", e.target.value)

        this.setState({

            website: e.target.value,
            urlError : !this.validateURL(e.target.value)
        }, ()=> {
            this.props.onUpdate("website", this.state.website)
            this.props.checkListingCompletion(this.state.businessName, this.state.contactNumber, this.state.urlError)
        })
       
    }

    onDescriptionChange = (e) => {

        localStorage.setItem("description", e.target.value)

        this.setState({
            description: e.target.value,
        }, ()=> {

            this.props.onUpdate("description", this.state.description)

        })
    }


    validateURL = (str)  => {

        if(str === '')
        {
            return true
        }
            
        if (validUrl.isUri(str)){
            return true
        } else {
           return false
        }
      
    }

    validateEmail = (email) => {

        if(email == '')
            return true

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    handelSelectFile = (e) => {
        
        let file = e.target.files[0]

        var reader = new FileReader()

        // Restrict files when they are bigger than 500kb
        if (file && file.size > 500000) {

            this.setState({

                showFileError: true,
            })
           
        }
        else
        {
            reader.onloadend = () => {

                localStorage.setItem("logo", reader.result,)

                this.setState({
                    logo: reader.result
                }, ()=> {

                    this.props.onUpdate('logo', this.state.logo)
                })
            }
            
            if (file instanceof Blob) {
                reader.readAsDataURL(file)
            }
        }
    }


    onUploadLogoByButton = () => {

        this.setState({

            showFileError : false
        })


        this.refs["file"].click()
    }

    onUploadLogoByImage = () => {

        this.setState({
            showFileError : false
        })

        this.refs["file"].click()
    }

    onContactUsClick = () => {

        return true
    }
    
    render() {

        return (
            <div className='banner-section'>
                <div className='expanded'>
                    <p>Please fill in the following information with regards to your listing:</p>
                    <ListingPreview
                        logo={this.state.logo}
                        businessName={this.state.businessName}
                        contactNumber={this.state.contactNumber}
                        email={this.state.email}
                        address={this.state.address}
                        website={this.state.website}
                        description={this.state.description}/>
                    <br/>
                    <br/>
                    <Grid columns='two' stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <label className='input-label'>Business Name</label>
                                <br/>
                                <Input 
                                    ref='businessName'
                                    className={'full-width ' + (!this.state.businessName ? 'error' : '')}
                                    value={ this.state.businessName }
                                    placeholder="Business Name"
                                    onChange={ this.onBusinessNameChange.bind(this) } />
                            
                            </Grid.Column>
                            <Grid.Column>
                                <label className='input-label'>Contact Number</label>
                                <br/>
                                <Input 
                                    ref='contactNumber'
                                    value={ this.state.contactNumber }
                                    className={'full-width ' + (!this.state.contactNumber ? 'error' : '')}
                                    placeholder="Contact Number"
                                    onChange={ this.onContactNumberChange.bind(this) }/>
                                <br/>
                                <br/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label className='input-label'>Email</label>
                                <label className='optional-label'>Optional</label>
                                <br/>
                                <Input 
                                    ref='email'
                                    className={'full-width ' + (this.state.emailError ? 'error' : '')}
                                    value={ this.state.email ? this.state.email : ''}
                                    placeholder="Email"
                                    onChange={ this.onEmailChange.bind(this) } />
                            
                            </Grid.Column>
                            <Grid.Column>
                                <label className='input-label'>Physical Address</label>
                                <label className='optional-label'>Optional</label>
                                <br/>
                                <Input 
                                    ref='address'
                                    value={ this.state.address ? this.state.address : '' }
                                    className='full-width'
                                    placeholder="Physical Address"
                                    onChange={ this.onAddressChange.bind(this) }/>
                                <br/>
                                <br/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                        
                                <label className='input-label'>Website <HelpTip tooltip={
                                        <span>
                                            <div>You can add any of the following links:
                                                <ul>
                                                    <li>Website</li>
                                                    <li>Facebook Page</li>
                                                    <li>Twitter Page</li>
                                                </ul>
                                                <p>* Please note your URL must start with <strong>http://</strong> or <strong>https://</strong></p>
                                            </div>
                                        </span>
                                    }/>
                                </label>
                                <label className='optional-label'>Optional</label>
                                <br/>
                                <Input 
                                    ref='website'
                                    className='full-width'
                                    error={ this.state.urlError }
                                    value={this.state.website ? this.state.website : ''}
                                    placeholder="http://www.example.com"
                                    onChange={ this.onWebsiteChange.bind(this) }/>
                                <br/>
                                <label>* Please note your URL must start with <strong>http://</strong> or <strong>https://</strong></label>
                                <br/><br/>
                                <br/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <label className='input-label'>Description</label>
                                <label className='optional-label'>Optional</label>
                                <br/>
                                <Input 
                                    ref='description'
                                    className='full-width'
                                    maxLength={ 100 }
                                    value={ this.state.description ? this.state.description : ''}
                                    placeholder="Description of your business"
                                    onChange={ this.onDescriptionChange.bind(this) } />
                                <div className='align-right'>
                                    <label className='input-label'>{ this.state.description.length }/100</label>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column >
                                <label className='input-label'>Logo
                                    <HelpTip tooltip={
                                            <span>
                                                We have provided you with a "CONTACT US NOW" image to use on your listing should you not have a logo to upload. 
                                            </span>
                                        }/>
                                </label>
        
                            </Grid.Column>
                        
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                            
                                <img 
                                ref="creativeImage" 
                                className='banner-logo' 
                                src={ this.state.logo } 
                                onClick={ this.onUploadLogoByImage.bind(this) }
                                style={{marginRight:"20px"}}/>
                                                                
                                {
                                    this.state.showFileError
                                    ? <div>
                                        <br/>
                                        <label className='red' textAlign='center'>
                                            Image too large. Please upload an image smaller than 500KB. 
                                        </label>
                                        <br/>
                                    </div>
                                    : null
                                }
                                <Button 
                                className='d6-media-green' 
                                onClick={ this.onUploadLogoByButton.bind(this)} >
                                    Upload Logo
                                </Button>
                                <input type="file"  
                                    id={"file"}
                                    ref={"file"}
                                    hidden={ true }
                                    onChange={ this.handelSelectFile.bind(this) }/>
                                
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
    
                </div>            
                <br/>
                <br/>
                <HelpMessage
                    message={
                        <div>
                            <p className='center'>
                                For assistance please
                            </p>
                            <span className='contact-us'><a onClick={this.onContactUsClick.bind(this)} target="_blank" href='https://d6.co.za/media/local-listings/'>Contact Us</a></span>
                        </div>
                    }/>
            </div>
        )
    }
}


export default ListingExp