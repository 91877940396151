
import React, { Component } from 'react'
import { assign, map, concat, reject, find, isUndefined, sumBy } from 'lodash'
import '.././styles/custom.css'
import { Message, Button, Segment, Dropdown, Grid } from 'semantic-ui-react'
import { constants } from '../constants'
import SelectedBox from '../components/SelectedBox'
import SearchBar from '../components/SearchBar'
import SchoolTable from '../components/SchoolTable'
import { getSchools, calculateAmount, getCountriesFilter, getProvincesFilter, getTownsFilter, getSuburbsFilter } from '../api';
import ReactGA from 'react-ga'
import moment from 'moment-business-days'


class SchoolSelection extends Component {


    constructor(props) {
        super()

        this.state = {
            selectedSchools: props.selectedSchools,
            userCount: props.userCount,
            amount: props.amount,
            subcategoryId: props.subcategoryId,
            planId: props.planId,
            perPage: null,
            page: 1,
            totalpages: 1,
            search: "",
            fetching: false,
            schools: [],
            regions: [],
            regionId: -1,
            countries: [],
            // countryId: process.env.REACT_APP_DEFAULT_COUNTRY_ID, // For some reason, this doesn't work
            countryId: 198,
            countrySearch: "",
            countrySearching: false,
            provinces: [],
            provinceId: -1,
            provinceSearch: "",
            provinceSearching: false,
            towns: [],
            townId: -1,
            townSearch: "",
            townSearching: false,
            suburbs: [],
            suburbId: -1,
            suburbSearch: "",
            suburbSearching: false,
        }

        
    }

    async getCountries() {
        this.setState({
            // fetching: true
        })

        var urlParams = [
            { search: this.state.countrySearch },
        ]

        await getCountriesFilter(urlParams).then((response) => {

            var countries = response.data

            if(!isUndefined(countries)) {
                this.setState({
                    // fetching: false,
                    countries
                })
            }

            return countries

        }).catch((error) => {

            this.setState({
                countrySearching: false
            })

        })
    }


    async getProvinces() {
        this.setState({
            // fetching: true
        })

        var urlParams = [
            { search: this.state.provinceSearch },
        ]

        await getProvincesFilter(this.state.countryId, urlParams).then((response) => {

            var provinces = response.data

            if(!isUndefined(provinces)) {
                this.setState({
                    // fetching: false,
                    provinces
                })
            }

            return provinces

        }).catch((error) => {

            this.setState({
                provinceSearching: false
            })

        })
    }

    async getTowns() {
        this.setState({
            // fetching: true
        })

        var urlParams = [
            { search: this.state.townSearch },
        ]

        await getTownsFilter(this.state.provinceId, urlParams).then((response) => {

            var towns = response.data

            if(!isUndefined(towns)) {
                this.setState({
                    // fetching: false,
                    towns
                })
            }

            return towns

        }).catch((error) => {

            this.setState({
                townSearching: false
            })

        })
    }

    async getSuburbs() {
        this.setState({
            // fetching: true
        })

        var urlParams = [
            { search: this.state.suburbSearch },
        ]

        await getSuburbsFilter(this.state.townId, urlParams).then((response) => {

            var suburbs = response.data

            if(!isUndefined(suburbs)) {
                this.setState({
                    // fetching: false,
                    suburbs
                })
            }

            return suburbs

        }).catch((error) => {

            this.setState({
                suburbSearching: false
            })

        })
    }

    async getSchools() {

        this.setState({
            fetching: true
        })

        var startDate = moment().format("YYYY-MM-DD")
        var endDate = moment().add(1000, 'days').format("YYYY-MM-DD")

        var urlParams = [

            { page: this.state.page },
            { per_page: this.state.perPage == null ? constants().PER_PAGE_OPTIONS[0].value : this.state.perPage },
            { search: this.state.search },
            { sort:  "name"},
            { sub_category_id: this.state.subcategoryId}
        ]

        if(this.state.countryId !== -1)
        {
            urlParams = concat(urlParams,  [{ country_id: this.state.countryId }])
            this.getProvinces(this.state.countryId)
        }

        if(this.state.provinceId !== -1)
        {
            urlParams = concat(urlParams,  [{ province_id: this.state.provinceId }])
            this.getTowns(this.state.provinceId)
        }

        if(this.state.townId !== -1)
        {
            urlParams = concat(urlParams,  [{ town_id: this.state.townId }])
            this.getSuburbs(this.state.townId)
        }

        if(this.state.suburbId !== -1)
        {
            urlParams = concat(urlParams,  [{ suburb_id: this.state.suburbId }])
        }

        if(this.state.regionId !== -1)
        {
            urlParams = concat(urlParams,  [{ region_id: this.state.regionId }])
        }

        await getSchools(urlParams).then((schoolsResponse) => {

            const data = schoolsResponse.data
            const pagination = data.pagination
            const schools = data.data

            this.setState({
                fetching: false,
                perPage: pagination.per_page ? pagination.per_page : 0,
                page: pagination.current_page ? pagination.current_page : 1,
                totalpages: pagination.total_pages ? pagination.total_pages : 1,
                schools
            })

        }).catch((error) => {

            this.setState({
                searching: false
            })

        })

    }

    calUserCount = (selectedSchools) => {

        var userCount = sumBy(selectedSchools, 'num_mobile_users')
        var schoolIds = map(selectedSchools, 'id')

        this.setState({
            userCount,
        })

        this.calAmount(this.props.startDate, this.props.endDate, schoolIds)
    }

    async calAmount(startDate, endDate, schoolIds) {

        var payload = {
            school_ids : schoolIds,
        }
        
        if(this.state.planId) {
            payload = assign(payload, {
                plan_id: this.state.planId
            })
        }

        await calculateAmount(payload).then((response) => {

            const data = response.data

            this.setState({

                amount: data.price_formatted,
                plan_id: data.plan_id
            })
           

        }).catch((error) => {

            this.setState({

                amount: "Error while Calculating Cost"
            })

        })
    }

    getSelectedSchools = (schools) => {

        var selection = []

        map(schools, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }


    getCountryOptions = (countries) => {

        var selection = []

        map(countries, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }

    onCountryChanged = (e, selectedItem) => {
        this.setState({

            countryId: selectedItem.value,
            provinceId: -1,
            townId: -1,
            towns: [],
            suburbId: -1,
            suburbs: []
        }, () => {
            this.getSchools()
        })
    }

    getProvinceOptions = (provinces) => {

        var selection = [
            { key: -1, text: "All", value: -1, id: null }
        ]

        map(provinces, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }

    onProvinceChanged = (e, selectedItem) => {
    
        this.setState({

            provinceId: selectedItem.value,
            townId: -1,
            suburbId: -1,
            suburbs: []
        }, () => {
          
            this.getSchools()
        })
    }

    getTownOptions = (towns) => {
        var selection = [
            { key: -1, text: "All", value: -1, id: null }
        ]

        map(towns, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }

    onTownChanged = (e, selectedItem) => {

        this.setState({

            townId: selectedItem.value,
            suburbId: -1
        }, () => {
            this.getSchools()
        })
    }

    getSuburbOptions = (suburbs) => {
        var selection = [
            { key: -1, text: "All", value: -1, id: null }
        ]

        map(suburbs, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }

    onSuburbChanged = (e, selectedItem) => {
    
        this.setState({

            suburbId: selectedItem.value
        }, () => {
            this.getSchools()
        })
    }

    getRegionOptions = (regions) => {

        var selection = [
            { key: -1, text: "All", value: -1, id: null }
        ]

        map(regions, (item, key) => {

            selection = concat(selection, [

                { key: key, text: item.name, value: item.id, id: item.id },
            ])

        })

        return selection

    }

    onRegionChanged = (e, selectedItem) => {

        this.setState({

            regionId: selectedItem.value
        }, () => {
            this.getSchools()
        })

    }

    onSchoolSelectionChange = (school) => {

        var _selectedSchools = concat([], this.state.selectedSchools)

        if (!isUndefined(find(_selectedSchools, ['id', school.id]))) {
            _selectedSchools = reject(_selectedSchools, ['id', school.id])
        }
        else {
            _selectedSchools = concat(_selectedSchools, school)
        }

        this.setState({

            selectedSchools: _selectedSchools
        })

        this.calUserCount(_selectedSchools)

    }

    onContinueClick() {

        this.props.onContinueClick(this.state.selectedSchools)
    }

    onPageChange = (page) => {

        this.setState({

            page: page
        }, () => {
            this.getSchools()
        })

    }

    onPaginationIntervalChange = (interval) => {

        this.setState({

            perPage: interval,
            page: 1
        }, () => {
            this.getSchools()
        })

    }

    onSearchChange = (search) => {

        this.setState({

            search: search,
            searching: true,
            page: 1
        }, () => {
            this.getSchools()
        })
    }

    componentDidMount() {
        this.getCountries()
        this.getSchools()
    }


    render() {

        const { startDate, endDate, onCancelClick } = this.props        

        return (
            <Segment basic className='full-width school-selection'>
                <Message className='primary olive'>
                    <div>Users: <strong className='primary-text'>Up to {this.state.userCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} </strong> </div>
                    <div>Number of Schools: <strong className='primary-text'>{this.state.selectedSchools.length}</strong> </div>
                    <div>Total Monthly Cost: <strong className='primary-text'>{ this.state.amount } (VAT incl.)</strong></div>
                </Message>

                <h3>Schools</h3>
                
                {
                    this.state.selectedSchools.length > 0
                    
                    ? <div>
                        <p>Add the following schools:</p>
                        <SelectedBox selection={this.getSelectedSchools(this.state.selectedSchools)} onRemoveItem={this.onSchoolSelectionChange.bind(this)} />
                     </div>
                    : null

                }

                 <div className='align-right'>
                    <Button color='red' onClick={onCancelClick.bind(this)}>Cancel</Button>
                    <Button className='d6-media-green' onClick={this.onContinueClick.bind(this)}>Apply Changes</Button>
                </div>

                
                <Segment basic className='grey-block'>
                    <p>Please select from the list below:</p>
                    <Grid className='filters' stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <label className='input-label'>Country</label>
                                <Dropdown
                                    value={ this.state.countryId }
                                    placeholder='Filter by Country' 
                                    fluid 
                                    search 
                                    selection 
                                    options={ this.getCountryOptions(this.state.countries) }
                                    onChange={ this.onCountryChanged.bind(this) } />
                            </Grid.Column>
                            <Grid.Column>
                                <label className='input-label'>Province</label>
                                <Dropdown
                                    value={ this.state.provinceId }
                                    placeholder='Filter by Province' 
                                    fluid 
                                    search
                                    selection
                                    options={ this.getProvinceOptions(this.state.provinces) }
                                    onChange={ this.onProvinceChanged.bind(this) } />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            {
                                this.state.provinceId != -1
                                ?<Grid.Column>
                                    <label className='input-label'>Town</label>
                                    <Dropdown
                                        value={ this.state.townId }
                                        placeholder='Filter by Town' 
                                        fluid 
                                        search
                                        selection 
                                        options={ this.getTownOptions(this.state.towns) }
                                        onChange={ this.onTownChanged.bind(this) } />
                                </Grid.Column>
                                : null
                            }
                            {
                                this.state.townId != -1
                                ?<Grid.Column>
                                    <label className='input-label'>Suburb</label>
                                    <Dropdown
                                        value={ this.state.suburbId }
                                        placeholder='Filter by Suburb' 
                                        fluid 
                                        search
                                        selection
                                        options={ this.getSuburbOptions(this.state.suburbs) }
                                        onChange={ this.onSuburbChanged.bind(this) } />
                                </Grid.Column>
                                : null
                            }
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <SearchBar placeholder='Search for School Name' onSearchChange={this.onSearchChange.bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <SchoolTable
                        schools={this.state.schools}
                        selectedSchools={this.state.selectedSchools}
                        currentPage={this.state.page}
                        totalPages={this.state.totalpages}
                        perPage={this.state.perPage}
                        loading={this.state.fetching}
                        provinces={ this.state.provinces }
                        onPageChange={this.onPageChange.bind(this)}
                        onPaginationIntervalChange={this.onPaginationIntervalChange.bind()}
                        onRowSelected={this.onSchoolSelectionChange.bind(this)} />

               </Segment>

            </Segment>
        )
    }
}

export default SchoolSelection
