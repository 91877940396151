import React, { Component } from 'react'
import '.././styles/custom.css'
import { Segment, Message } from 'semantic-ui-react'
import ReactGA from 'react-ga'


class Completed extends Component {

    constructor(props) {
        super()

        this.state = {
            
        }
    }

    OnCreateBannerClick = () => {
        
        localStorage.clear()
        
        return true
    }



    render() {

        const {message} = this.props

        return (
            <Segment basic textAlign='center'>

                <img className='completed-logo' src='/images/logo_horizontal.png' />

                <h1 className='center'>Thank You</h1>   
                <Message className='primary olive center'>
                    <p textAlign='center'>{message}</p>
                </Message> 
                <Segment basic textAlign='center' className="full-width">
                    <p>For assistance please</p>
                    <span className='contact-us'><a target="_blank" href='https://d6.co.za/media/local-listings/'>Contact Us</a></span>
                </Segment>
                <br/>
                <p>
                    <a onClick={this.OnCreateBannerClick.bind(this)} href={ process.env.REACT_APP_URL }>Create New Listing</a>
                </p>
            </Segment>
        )
    }
}

export default Completed

